import { eraseCookie } from "../../Utils/CookieController";

const initState = {
    user: null,
    logged: false,
    counter: 0,
    token: null
};

export const userReducer = (state: any = initState, action: any) => {
    if(action.type === 'user/setToken') {
        state.token = action.token;
        return { ...state };
    }

    if(action.type === 'user/setUser') {
        state.user = action.user;
        return { ...state };
    }

    if(action.type === 'user/setRole') {
        state.user.permissionInfo.permission = action.role;
        return { ...state };
    }

    if(action.type === 'user/setPrefix') {
        state.user.prefix = action.prefix;
        return { ...state };
    }

    if(action.type === 'user/setSuffix') {
        state.user.suffix = action.suffix;
        return { ...state };
    }

    if(action.type === 'user/setSkin') {
        state.user.skin = action.skin;
        return { ...state };
    }

    if(action.type === 'user/setCape') {
        state.user.cape = action.cape;
        return { ...state };
    }

    if(action.type === 'user/logout') {
        state.user = null;
        eraseCookie("token");
        return { ...state };
    }

    if(action.type === 'user/setPlaytime') {
        state.user.playtime = action.playtime;
        return { ...state };
    }

    if(action.type === 'user/setBalance') {
        if(typeof action.money === "number") state.user.money = action.money;
        if(typeof action.bubbles === "number") state.user.bubbles = action.bubbles;
        return { ...state };
    }

    return state;
};