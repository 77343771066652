import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {LinkTool} from "./LinkTool";

export const ICafe: React.FC = () => {
    return (
        <Routes>
            <Route path="link" element={<LinkTool/>}/>
        </Routes>
    )
};