import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {apiCall} from "../../API/ApiCall";
import {noty} from "../../Utils/NotyController";
import {CafeDetailsItem, ICafeDetailsItem} from "./CafeDetailsItem";
import {UserDetailsItem} from "../Users/UserDetailsItem";

export const CafeDetails: React.FC = () => {
    const [cafeDetails, setCafeDetails] = useState<ICafeDetailsItem>();
    const {cafeId} = useParams();

    useEffect(() => {
        apiCall.get("/Cafe/GetCafeById?id=" + cafeId, {}).then(response => {
            setCafeDetails(response.data);
        }).catch(e => {
            console.log(e);
        });

        return () => {
        };
    }, [cafeId]);

    return (
        cafeDetails ? <CafeDetailsItem id={cafeDetails!.id}
        name={cafeDetails!.name}
        address={cafeDetails!.address}
        crmType={cafeDetails!.crmType}
        social={cafeDetails!.social}
        crmCredential={cafeDetails!.crmCredential}
        cafeSettings={cafeDetails!.cafeSettings}
        usersCount={cafeDetails!.usersCount}/> : <div>Loading...</div>
    );
};