const initState = {
    login: false,
    registration: false,
    rules: false,
    play: false,
    recovery: false,
    tokenrecovery: false
};

export const modalReducer = (state: any = initState, action: any) => {
    if(action.type === 'modal/showModal') {
        state[action.name] = true;
        return { ...state };
    }

    if(action.type === 'modal/hideModal') {
        state[action.name] = false;
        return { ...state };
    }

    return state;
};