import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {CreatePayment} from "./CreatePayment";
import {PaymentSuccess} from "./PaymentSuccess";

export const Pay: React.FC = () => {
    return (
        <Routes>
            <Route path="create" element={<CreatePayment/>}/>
            <Route path="success" element={<PaymentSuccess/>}/>
        </Routes>
    )
};