import React, {useEffect, useState} from 'react';
import {ErrorAlert, IErrorAlertProps} from "../../Components/ErrorAlert";
import { Form, Button } from 'react-bootstrap';
import {IUserDetailsItem} from "./UserDetailsItem";
import {apiCall} from "../../API/ApiCall";

export const CreateUser: React.FC = () => {
    const [errorAlert, setErrorAlert] = useState<IErrorAlertProps>({ message: null, show: false });
    const closeAlert = (): void => setErrorAlert({ message: null, show: false });

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    function onChangeUsername(event: React.ChangeEvent<HTMLInputElement>) {
        setUsername(event.target.value);
    }

    function onChangePassword(event: React.ChangeEvent<HTMLInputElement>) {
        setPassword(event.target.value);
    }

    const [cafes, setCafes] = useState<Array<any>>([]);

    useEffect(() => {
        apiCall.get("/Cafe/GetCafesForDropdown").then(response => {
            setCafes(response.data);
        }).catch(e => {
            console.log(e);
        });
    });
    
    return (
        <div className="page-container">
            <div className="page-title">
                <h3>Создать пользователя</h3>
            </div>
            <form style={{"margin": "50px"}}>
                <ErrorAlert message={ errorAlert.message } show={ errorAlert.show } close={ closeAlert }/>

                <div className="form-group">
                    <span className="label">Юзернейм</span>
                    <input type="text" placeholder="Имя пользователя" value={ username } onChange={ onChangeUsername } className="value"/>
                </div>
                
                <div className="form-group">
                    <span className="label">Клуб</span>
                    {
                        (cafes.length > 0) ?
                            <Form.Select aria-label="Клуб" className="value">
                                {
                                    cafes.map((cafe: any) => {
                                        return (
                                            <option value={cafe.id}>{cafe.name}</option>
                                        )
                                    })
                                }
                            </Form.Select>
                            : <div>no cafes found</div>
                    }
                    
                </div>

                {/*<div className="form-group" id="email">
                    <Form.Control type="email" placeholder="user@mail.ru" value={ email } onChange={ onChangeEmail } autoComplete="new-password"/>
                </div>

                <div className="form-group" id="password">
                    <span className="label">Пароль</span>
                    <Form.Control type="password" placeholder="Пароль" value={ password } onChange={ onChangePassword } autoComplete="new-password"/>
                </div>

                <div className="form-group actions">
                    <button className="action-btn center" onClick={ makeRegistration } disabled={ !allowRegistration }>Зарегистрироваться</button>
                </div>*/}
            </form>
        </div>
    );
}