import React, {StrictMode, useEffect, useState} from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import {Routes, Route} from 'react-router-dom';
import './App.scss'

import {Login} from './Components/Login'
import {Signup} from './Components/Signup'
import {Layout} from "./Containers/Layout";

function App() {
    return (
        <StrictMode>
            <Layout/>
        </StrictMode>
    );
}

export default App;