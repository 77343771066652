import React, {useContext, useState} from 'react';
import {Header} from "../Header";
import {Route, Routes, useLocation} from 'react-router-dom';
import {ReactReduxContext, useSelector} from 'react-redux';
import {Login} from "../../Components/Login";
import {Signup} from "../../Components/Signup";
import {Home} from "../Home";
import {Users} from "../Users";
import {Cafes} from "../Cafes";
import {ICafe} from "../ICafe";
import {Pay} from "../Pay";

export const Layout: React.FC = () => {
    const { store } = useContext(ReactReduxContext);
    
    const accessToken = localStorage.getItem("accessToken");
    const publicRoutes = ["pay"];
    const isPublicRoute = publicRoutes.includes(window.location.pathname.split("/")[1]);
    
    let loading = false;
    
    return (
        <>
            {accessToken ?
                <>
                    <Header/>
                    <div className="baseContainer">
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/users/*" element={<Users/>}/>
                            <Route path="/cafes/*" element={<Cafes/>}/>
                            <Route path="/icafe/*" element={<ICafe/>}/>
                        </Routes>
                    </div>
                </>
                :
                <>
                    {isPublicRoute ?
                        <>
                            <Routes>
                                <Route path="/pay/*" element={<Pay/>}/>
                            </Routes>
                        </>
                        :
                        <Login/>
                    }
                </>
            }
        </>
    )
};