import React, {useContext, useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {apiCall} from "../../API/ApiCall";
import {noty} from "../../Utils/NotyController";
import {Button, Pagination, Table} from "react-bootstrap";
import {DetailsIcon} from "../../Components/Icons/Details";
import {EditIcon} from "../../Components/Icons/Edit";
import axios from "axios";
import {getCookie} from "../../Utils/CookieController";
import {ReactReduxContext} from "react-redux";

export const CreatePayment: React.FC = () => {
    const [amount, setAmount] = useState(10);
    const [cafe, setCafe] = useState("");
    const [username, setUsername] = useState("");
    const [cafeId, setCafeId] = useState(0);
    const [usernameDisabled, setUsernameDisabled] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);

        if (params.get("username") != undefined) {
            setUsername(params.get("username")!);
            setUsernameDisabled(true);
        }

        if (params.get("cafeId") != undefined) {
            const apiCall = axios.create({
                baseURL: 'https://payment.katana-soft.xyz/',
            });
            
            apiCall.get(`/Cafe/GetCafeInfo?icafeId=${params.get("cafeId")}`)
                .then(response => {
                    setCafeId(parseInt(response.data.id));
                    const cafeName = response.data.name;
                    if (cafeName !== null && cafeName !== undefined) {
                        setCafe(cafeName);
                    }
                });
        }
    }, []);
    
    async function onFormSubmit(e: React.FormEvent<HTMLElement>): Promise<void> {
        e.preventDefault();
        
        setLoading(true);

        const apiCall = axios.create({
            baseURL: 'https://payment.katana-soft.xyz/',
        });

        window.location.href = (await apiCall.post(`/Pay/CreateTransaction?cafeId=${cafeId}&username=${username}&amount=${amount}`)).data.PaymentURL;
    }
    
    function onChangeAmount(event: React.ChangeEvent<HTMLInputElement>) {
        setAmount(parseInt(event.target.value));
    }

    function onChangeUsername(event: React.ChangeEvent<HTMLInputElement>) {
        setUsername(event.target.value);
    }

    function onChangeCafe(event: React.ChangeEvent<HTMLInputElement>) {
        setCafe(event.target.value);
    }

    return (
        <>
            <div className="loader-container" style={{display: `${loading ? "block" : "none"}`}}>
                <div className="spinner"></div>
            </div>
        
            <div className="baseContainer" style={{marginTop: "10%", marginLeft: "30%", marginRight: "30%",}}>
                <div className="page-container">
                    <form onSubmit={onFormSubmit}>
                        <div className="page-title">
                            <h3>Пополнение баланса</h3>
                        </div>
        
                        <div className="text-center" style={{marginLeft: "60px", marginRight: "60px", marginBottom: "40px", marginTop: "60px"}}>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">Клуб</span>
                                </div>
                                <input type="text" required className="form-control" placeholder="Клуб" disabled value={cafe} onChange={onChangeCafe}/>
                            </div>
        
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">Логин</span>
                                </div>
                                <input type="text" required className="form-control" id="username" placeholder="Имя пользователя" disabled={usernameDisabled} value={username} onChange={onChangeUsername}/>
                            </div>
        
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">Сумма</span>
                                </div>
                                <input type="number" required id="amount" className="form-control" placeholder="Сумма пополнения" aria-label="Amount"
                                       step="1" min="10" value={amount} onChange={onChangeAmount}/>
                                <div className="input-group-append">
                                    <span className="input-group-text">руб.</span>
                                </div>
                            </div>
        
                            <button type="submit" className="btn btn-primary text-center"
                                    style={{width: "40%", marginTop: "40px"}}>
                                Оплатить
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};