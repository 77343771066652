import axios from 'axios';
import { getCookie } from "../Utils/CookieController";

export const apiCall = axios.create({
    baseURL: 'https://localhost:4242/',
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie("token")}`
    }
});

apiCall.interceptors.request.use(
    function(config) {
        const token = localStorage.getItem("accessToken");
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;
        }
        return config;
    },
    function(error) {
        return Promise.reject(error);
    }
);