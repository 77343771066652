import React, { useRef, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { apiCall } from "../../API/ApiCall";
import { setCookie } from "../../Utils/CookieController";
import { useDispatch } from "react-redux";
import { ErrorAlert, IErrorAlertProps } from "../ErrorAlert";
import { noty } from "../../Utils/NotyController";
import { Link } from 'react-router-dom';
import "./index.css";
import LogoSVG from "../../Components/Logo";

export const Login: React.FC = () => {
    const [username, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [errorAlert, setErrorAlert] = useState<IErrorAlertProps>({ message: null, show: false });

    const closeAlert = (): void => setErrorAlert({ message: null, show: false });

    function onChangeLogin(event: React.ChangeEvent<HTMLInputElement>) {
        setLogin(event.target.value);
    }

    function onChangePassword(event: React.ChangeEvent<HTMLInputElement>) {
        setPassword(event.target.value);
    }

    async function onFormSubmit(e: React.FormEvent<HTMLElement>): Promise<void> {
        e.preventDefault();
        await makeLogin();
    }

    async function makeLogin(): Promise<any> {
        await apiCall.post("/user/Authenticate", { username, password })
            .then(response => {
                if(response.data.token) {
                    localStorage.setItem("accessToken", response.data.token);
                    noty(`Авторизация успешна!`, "success");
                }
            }).catch(e => {
                setErrorAlert({ message: [e.response.data.message], show: true });
            });
    }

    return (
        <>
            <div className="Logo"><LogoSVG/></div>
            <div className="auth-box">
                <form onSubmit={onFormSubmit}>
                    <h3 style={{"textAlign": "center"}}>Вход</h3>

                    <ErrorAlert message={errorAlert.message} show={errorAlert.show} close={closeAlert}/>

                    <div className="mb-3">
                        <label>Логин</label>
                        <Form.Control type="text" placeholder="Введите логин" value={username}
                                      onChange={onChangeLogin}/>
                    </div>

                    <div className="mb-3">
                        <label>Пароль</label>
                        <Form.Control type="password" placeholder="Введите пароль" value={password}
                                      onChange={onChangePassword}/>
                    </div>

                    <div className="d-grid">
                        <button type="submit" className="btn btn-primary">
                            Войти
                        </button>
                    </div>
                    <div style={{"display": "none"}}>
                        <Link className="register-button" to={'/sign-up'}>
                            Регистрация
                        </Link>
                        <Link className="forgot-password" to={'/sign-up'}>
                            Забыли пароль?
                        </Link>
                    </div>
                </form>
            </div>
        </>
    )
};