import React from 'react';
import { Alert } from 'react-bootstrap';

export interface IErrorAlertProps {
    message: Array<string> | null
    show: boolean,
    close?: Function
}

export const ErrorAlert: React.FC<IErrorAlertProps> = ({ message, show, close }) => {

    if (show && message) {
        return (
            <Alert variant="danger" onClose={ () => (close) ? close() : null } dismissible>
        <strong>Ошибка</strong>
        <ul>
        {
            message.map((err, index) => {
                return <li key={index}>{ err }</li>
            })
        }
        </ul>
        </Alert>
    )
    } else return null;
};