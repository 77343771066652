import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {Login} from "../../Components/Login";
import {UsersList} from "./UsersList";
import {UserDetails} from "./UserDetails";
import {CreateUser} from "./CreateUser";

export const Users: React.FC = () => {
    return (
        <Routes>
            <Route path="list" element={<UsersList/>}/>
            <Route path="add" element={<CreateUser/>}/>
            <Route path="details/:userId" element={<UserDetails/>}/>
        </Routes>
    )
};