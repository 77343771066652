import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import {IUserDetailsItem, UserDetailsItem} from "./UserDetailsItem";
import {apiCall} from "../../API/ApiCall";
import {noty} from "../../Utils/NotyController";

export const UserDetails: React.FC = () => {
    const [userDetails, setUserDetails] = useState<IUserDetailsItem>();
    const {userId} = useParams();

    useEffect(() => {
        apiCall.get("/User/GetUserById?id=" + userId, {}).then(response => {
            setUserDetails(response.data);
        }).catch(e => {
            console.log(e);
        });
        
        return () => {};
    }, [userId]);
    
    return (
        userDetails ? <UserDetailsItem id={userDetails!.id} 
                         username={userDetails!.username} 
                         cafeName={userDetails!.cafeName} 
                         isBanned={userDetails!.isBanned} 
                         social={userDetails!.social} 
                         gambleInfo={userDetails!.gambleInfo} 
                         roles={userDetails!.roles}
        /> : <div>Loading...</div>
    );
};