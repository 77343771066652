import React from 'react';

const LogoSVG = () => {
    return (
        <svg style={{"height": "100%", "width": "100%"}} version="1.0" xmlns="http://www.w3.org/2000/svg"
             width="4790.000000pt" height="2397.000000pt" viewBox="0 0 4790.000000 2397.000000"
             preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,2397.000000) scale(0.100000,-0.100000)"
               fill="#ff3957" stroke="none">
                <path d="M21635 23957 c-30 -16 -43 -35 -80 -112 -125 -263 -204 -661 -270
-1360 -14 -148 -35 -344 -46 -435 -11 -91 -26 -318 -34 -505 -8 -187 -22 -491
-31 -675 -11 -214 -17 -499 -17 -790 l0 -455 55 -695 c31 -382 54 -697 52
-699 -3 -3 -134 14 -292 37 -158 24 -397 56 -532 72 -322 38 -424 56 -539 91
-123 39 -237 56 -282 43 -52 -14 -67 -57 -80 -216 -16 -226 13 -460 75 -601
34 -77 99 -171 141 -203 49 -37 104 -41 206 -15 117 30 490 159 635 219 316
131 537 183 564 133 62 -117 119 -879 210 -2836 6 -121 24 -557 40 -970 67
-1668 86 -2069 116 -2420 40 -480 130 -1107 194 -1355 77 -295 139 -742 156
-1110 13 -306 74 -431 281 -579 197 -142 396 -201 582 -172 83 13 123 33 143
69 17 33 15 172 -5 287 -9 50 -23 137 -31 195 -31 223 -49 259 -136 280 -28 7
-62 9 -76 6 -96 -24 -198 111 -274 364 -65 215 -102 319 -135 390 -65 133 -93
158 -152 133 -24 -10 -39 -11 -53 -3 -41 22 -37 84 12 185 36 72 34 111 -7
120 -14 3 -36 7 -50 10 -58 12 -117 220 -144 510 -30 322 -43 590 -32 647 22
103 80 144 100 71 15 -52 14 -118 -5 -263 -15 -122 -13 -230 6 -260 12 -20 44
20 69 87 55 142 103 636 82 846 -11 113 -30 166 -62 174 -79 21 -102 301 -120
1398 l-12 720 37 145 c92 358 216 761 372 1201 95 272 173 521 239 764 58 213
121 483 130 555 10 88 43 282 75 445 16 83 47 260 70 395 22 135 43 257 46
272 4 20 1 28 -15 33 -78 24 50 44 419 64 168 10 306 16 308 15 2 -1 12 -168
22 -370 57 -1106 122 -1641 220 -1835 60 -117 133 -77 183 101 25 86 43 198
72 438 47 387 219 804 359 872 52 25 62 25 109 0 52 -28 71 -15 112 73 149
324 187 895 85 1282 -79 301 -190 487 -335 563 -69 36 -169 38 -235 4 -108
-56 -213 -203 -287 -402 -32 -86 -105 -222 -151 -283 -20 -27 -55 -59 -77 -72
-57 -34 -187 -69 -366 -99 -85 -14 -189 -37 -229 -51 -40 -14 -76 -23 -79 -20
-2 3 -7 31 -9 63 -3 41 9 119 42 282 26 124 62 311 80 415 19 105 48 250 65
322 102 442 160 933 168 1428 13 748 -77 1370 -252 1742 -166 353 -890 1085
-1250 1266 -82 40 -122 50 -150 34z m291 -9081 c-30 -164 -57 -301 -60 -304
-19 -18 9 317 34 425 18 77 66 191 76 181 2 -3 -21 -139 -50 -302z"/>
                <path d="M3320 23925 c0 -9 14 -42 30 -75 17 -33 30 -74 30 -92 0 -37 -24 -78
-45 -78 -23 0 -75 50 -97 94 -32 63 -79 125 -100 132 -44 14 -74 -47 -108
-224 -22 -112 -61 -414 -82 -622 -17 -179 -17 -1306 0 -1660 39 -773 101
-1579 207 -2665 31 -326 34 -390 45 -945 11 -618 20 -913 45 -1580 20 -518 35
-1181 35 -1552 l0 -298 -22 0 c-37 0 -117 42 -332 172 -282 172 -504 322 -771
523 -232 175 -333 264 -363 320 -9 16 -19 64 -22 107 -8 99 -33 134 -133 183
-70 34 -182 64 -242 65 -73 0 -245 -155 -364 -329 -32 -47 -91 -140 -130 -205
-180 -297 -189 -335 -212 -876 -36 -814 4 -981 289 -1211 121 -98 245 -175
571 -356 166 -92 303 -172 303 -178 0 -5 -87 -116 -194 -246 l-193 -236 -383
-213 c-228 -127 -422 -229 -480 -250 -296 -113 -530 -258 -574 -358 -33 -74
-5 -173 59 -205 33 -17 160 -43 168 -35 3 3 -2 24 -11 48 -15 37 -15 43 -2 57
9 8 42 18 74 22 70 9 111 28 181 87 99 84 207 114 418 114 180 0 209 15 170
90 -20 38 -10 58 34 69 20 5 95 23 166 40 143 35 254 85 525 239 194 110 256
158 447 345 89 87 177 164 195 172 28 11 46 11 109 0 131 -22 280 -65 429
-122 80 -30 237 -91 349 -133 150 -57 207 -83 212 -98 40 -107 78 -1182 79
-2207 1 -558 7 -786 26 -903 5 -32 13 -46 28 -52 12 -4 27 -14 34 -23 23 -27
53 -172 59 -283 7 -123 -2 -163 -48 -227 -34 -47 -64 -171 -79 -324 -11 -118
-4 -181 23 -211 33 -37 41 -102 39 -337 -2 -297 -18 -525 -47 -700 -41 -239
-89 -376 -156 -440 -87 -83 -125 -260 -86 -396 36 -123 87 -141 166 -60 68 70
129 192 152 305 66 326 125 1093 239 3136 22 393 49 897 60 1120 31 621 53
870 101 1150 28 162 46 218 80 253 27 27 33 29 77 22 184 -26 956 -232 1462
-390 400 -124 1156 -341 1650 -474 1223 -327 2409 -554 3428 -656 247 -24 382
-47 471 -79 44 -16 222 -23 255 -10 10 4 16 18 16 36 0 28 2 30 27 23 15 -3
68 -31 117 -61 50 -30 120 -64 156 -75 154 -47 402 -86 735 -114 543 -45 792
-46 817 -2 22 41 172 54 368 32 161 -18 320 -16 374 4 22 9 27 16 23 33 -20
76 104 87 208 18 22 -15 57 -33 79 -40 36 -12 120 -9 133 4 9 9 -18 36 -58 56
-34 18 -405 125 -969 280 -652 180 -1322 394 -2670 853 -2401 818 -3435 1187
-4335 1547 -903 361 -1559 652 -1649 730 -45 40 -45 64 -1 106 53 50 200 137
283 167 44 15 82 37 93 51 27 34 1246 875 1749 1205 1273 837 2335 1582 2894
2029 244 195 371 307 544 477 311 304 381 413 457 716 107 422 149 1090 91
1451 -19 118 -94 428 -140 578 -106 351 -297 700 -381 700 -27 0 -40 -19 -54
-79 -20 -90 27 -155 103 -145 54 8 57 0 38 -89 -11 -51 -23 -78 -47 -103 l-33
-34 -73 6 c-41 3 -80 7 -86 10 -22 6 -14 40 18 80 17 21 26 40 20 42 -16 5
-81 -50 -87 -74 -8 -31 13 -101 49 -164 60 -103 55 -117 -23 -76 -62 33 -84
33 -116 1 -24 -24 -25 -30 -19 -88 9 -81 -7 -120 -71 -175 -27 -23 -78 -67
-114 -98 -88 -76 -109 -89 -121 -77 -16 16 -1 38 92 139 89 97 120 147 102
165 -8 8 -17 8 -30 0 -29 -15 -127 -122 -275 -300 -258 -309 -388 -445 -466
-485 -42 -22 -216 -239 -329 -411 -155 -234 -202 -285 -238 -255 -28 23 -91
-34 -67 -63 18 -21 25 -83 12 -107 -13 -24 -66 -50 -102 -50 -35 0 -422 -395
-1074 -1095 -709 -762 -873 -933 -1350 -1405 -831 -824 -1716 -1622 -1888
-1704 -37 -17 -40 -17 -52 -2 -20 29 -27 232 -20 616 29 1564 148 3567 262
4385 14 99 56 335 94 525 132 662 160 924 160 1495 0 390 -35 700 -116 1033
-61 249 -122 397 -197 479 -51 55 -84 73 -137 73 l-40 0 0 43 c0 23 5 59 10
79 14 50 -10 118 -88 242 -170 269 -337 438 -450 453 -38 5 -42 3 -66 -33 -31
-47 -50 -133 -42 -185 8 -47 -2 -49 -42 -8 -37 37 -83 124 -107 199 -35 109
-92 200 -126 200 -10 0 -19 -7 -19 -15z m1000 -1191 c20 -51 12 -101 -20 -136
-30 -32 -30 -33 -46 -13 -17 23 -12 56 17 96 10 15 19 39 19 53 0 33 18 34 30
0z m-11 -1018 c24 -24 41 -49 37 -54 -11 -19 -92 -24 -130 -8 -32 13 -36 19
-36 50 0 38 18 55 60 56 17 0 40 -14 69 -44z m-468 -13711 c12 -28 18 -54 13
-68 -7 -25 -43 -67 -56 -67 -15 0 -25 44 -17 75 4 16 10 47 14 68 3 20 10 37
16 37 6 0 19 -20 30 -45z"/>
                <path d="M42464 19695 c-67 -28 -92 -93 -104 -275 -9 -125 -13 -166 -25 -290
-3 -29 25 42 81 200 125 359 128 367 132 348 8 -38 -28 -337 -49 -403 -50
-162 -118 -245 -201 -245 -72 0 -106 90 -93 247 4 44 3 122 -1 173 -8 109 -14
107 -67 -22 -41 -101 -61 -214 -56 -326 3 -66 8 -85 32 -119 17 -24 30 -59 33
-90 14 -110 -46 -555 -106 -793 -81 -321 -103 -445 -104 -580 -1 -124 0 -127
33 -192 55 -109 29 -146 -109 -158 -103 -9 -134 -28 -127 -78 2 -21 17 -66 32
-101 84 -197 68 -489 -28 -503 -44 -6 -52 -46 -52 -268 0 -177 -2 -209 -18
-237 -20 -37 -66 -83 -84 -83 -16 0 -60 -54 -87 -109 -26 -50 -71 -193 -81
-254 -8 -51 -67 -110 -131 -131 -145 -47 -456 -58 -1514 -54 -822 3 -890 2
-955 -15 -185 -47 -254 -135 -290 -368 -22 -149 -39 -712 -25 -843 38 -348
195 -746 326 -827 30 -18 45 -21 95 -16 77 7 146 42 234 117 109 95 165 101
165 18 0 -104 58 -134 243 -125 138 7 284 38 517 110 163 51 493 143 585 163
63 14 100 10 108 -10 19 -48 -112 -486 -235 -790 -50 -126 -490 -1015 -650
-1316 -104 -193 -168 -323 -168 -336 0 -8 40 -3 64 7 29 13 61 7 96 -20 l24
-19 -38 -23 -39 -23 29 -24 c40 -33 103 -62 139 -62 17 0 52 -7 79 -15 73 -22
101 -19 139 15 19 16 38 30 42 30 4 0 5 -22 3 -50 -3 -50 -3 -50 15 -27 66 81
442 666 887 1377 325 519 758 1176 911 1380 218 291 338 376 639 452 63 15
151 39 195 53 44 13 118 33 165 45 116 29 150 47 166 87 l14 33 83 0 c45 0
120 -7 167 -15 113 -21 275 -31 283 -18 11 18 -13 80 -58 149 -68 103 -58 144
33 144 72 0 137 -63 168 -164 9 -30 19 -125 25 -231 5 -99 19 -243 30 -320 33
-233 150 -746 240 -1060 102 -351 235 -635 298 -635 9 0 33 -5 54 -11 43 -12
46 -27 16 -75 -18 -30 -19 -40 -10 -84 28 -128 141 -299 320 -482 213 -220
321 -266 321 -139 0 72 -86 224 -157 278 -41 32 -105 37 -159 13 -41 -18 -72
-8 -123 37 -36 32 -44 45 -48 86 -7 57 6 61 61 18 65 -52 138 -49 152 5 13 51
-20 156 -64 207 -8 9 -13 19 -10 22 7 7 176 -144 241 -215 155 -171 267 -385
267 -509 0 -92 32 -151 83 -151 40 0 86 32 106 73 21 44 41 174 41 268 0 78
-19 99 -96 110 -48 6 -64 14 -86 39 -50 55 -30 150 32 150 17 0 34 -8 42 -19
16 -22 51 -34 78 -26 34 11 25 56 -20 107 -47 54 -91 77 -172 88 -100 14 -166
82 -263 272 -178 345 -272 724 -380 1525 -65 491 -95 670 -130 783 -14 47 -28
94 -31 105 -5 19 0 20 158 21 297 2 966 84 1108 136 106 39 170 137 170 258 0
174 -183 309 -516 379 -163 35 -252 41 -641 48 l-373 6 0 24 c0 13 18 216 40
451 93 1005 90 1495 -11 1803 -56 174 -142 322 -299 514 -44 55 -140 174 -213
265 -436 545 -687 787 -1029 991 -173 103 -437 177 -514 144z m1640 -1940 c67
-32 90 -57 82 -88 -3 -13 -41 -62 -83 -108 -43 -47 -90 -100 -105 -119 -34
-45 -45 -35 -68 64 -14 57 -16 90 -10 139 8 66 30 121 52 130 31 12 83 5 132
-18z m31 -533 c11 -28 18 -177 11 -216 -8 -40 -32 -48 -58 -18 -28 30 -60 100
-65 142 -5 37 -3 44 36 80 42 40 64 43 76 12z m-185 -822 c11 -146 28 -332 37
-415 22 -213 23 -419 1 -467 -26 -57 -55 -67 -198 -66 -69 0 -156 -5 -193 -11
-118 -20 -179 12 -192 103 -13 85 47 312 131 496 19 41 112 211 207 377 153
270 173 300 179 275 4 -15 16 -146 28 -292z m-3383 -2001 c38 -12 73 -52 73
-83 0 -11 -14 -50 -31 -86 -64 -137 -75 -136 -253 19 -110 96 -110 92 -1 140
59 26 79 30 125 25 30 -3 70 -10 87 -15z m-491 -173 c65 -65 41 -88 -101 -94
-114 -5 -155 1 -155 23 0 32 129 104 189 105 24 0 42 -9 67 -34z"/>
                <path d="M33606 19680 c-2 -14 -7 -79 -10 -145 -4 -73 -16 -165 -32 -235 -89
-401 -107 -512 -124 -780 -14 -223 -20 -277 -35 -297 -13 -17 -14 -17 -35 2
-35 31 -101 171 -205 436 -54 134 -108 265 -122 291 -59 108 -119 136 -173 78
-46 -49 -62 -110 -67 -252 -9 -228 25 -367 150 -610 97 -188 118 -273 125
-488 9 -307 -36 -687 -202 -1700 -60 -367 -136 -880 -191 -1285 -89 -653 -141
-934 -269 -1451 -47 -188 -92 -389 -102 -448 -16 -100 -16 -113 -1 -207 21
-129 63 -254 137 -406 88 -183 165 -293 239 -342 16 -10 51 -16 105 -17 60 -1
101 -9 156 -29 115 -42 590 -255 825 -370 361 -177 542 -244 685 -252 69 -5
88 -3 98 10 20 24 5 60 -57 134 -71 84 -90 120 -98 182 -7 64 10 137 57 242
51 114 57 139 35 139 -9 0 -29 -8 -43 -19 -40 -28 -66 -33 -88 -17 -16 12 -19
27 -19 103 0 48 5 114 11 147 19 99 -11 169 -72 169 -36 0 -78 -49 -100 -115
-8 -26 -20 -48 -25 -48 -17 0 -37 62 -68 214 -35 166 -40 259 -15 295 15 21
22 23 63 16 25 -4 66 -8 91 -9 44 -1 45 -1 48 34 5 52 -15 106 -55 151 -64 70
-130 88 -180 49 -29 -23 -52 -26 -54 -7 -3 25 3 43 21 62 30 32 47 84 54 166
11 118 -21 530 -84 1079 -117 1019 -137 1512 -70 1667 29 67 68 66 136 -4 42
-45 248 -323 389 -527 72 -104 176 -250 233 -325 56 -75 278 -370 494 -656
408 -542 563 -740 706 -903 121 -137 288 -301 381 -371 135 -103 179 -169 212
-317 38 -168 116 -384 211 -583 27 -59 77 -173 110 -256 130 -330 231 -483
254 -388 11 42 -4 296 -26 448 -23 158 -26 298 -7 335 19 37 59 37 116 1 26
-17 51 -31 55 -31 12 0 -14 39 -79 121 -99 121 -120 174 -136 329 -4 47 -13
105 -18 130 -6 25 -10 93 -11 151 0 91 3 110 19 130 l19 24 22 -20 c12 -11 33
-42 47 -70 37 -74 60 -95 103 -95 45 0 76 21 88 63 6 17 13 97 16 177 8 182
22 314 96 880 33 250 91 709 130 1020 79 639 88 693 131 810 17 47 48 146 70
220 87 298 97 375 68 570 -34 234 -21 343 45 352 29 4 52 56 71 163 56 319 0
997 -106 1290 -104 285 -454 688 -812 933 -257 176 -545 271 -706 232 -69 -16
-160 -86 -201 -152 -40 -64 -91 -220 -119 -358 -39 -200 -101 -708 -108 -885
-19 -544 86 -1474 332 -2958 116 -696 181 -1199 205 -1599 16 -262 -9 -408
-71 -416 -35 -4 -62 20 -181 165 -253 308 -469 601 -725 985 -784 1176 -1303
2083 -1422 2483 -37 124 -140 783 -185 1180 -41 365 -50 501 -67 974 -13 399
-31 573 -64 617 -13 18 -14 18 -19 -6z m3047 -1297 c14 -16 33 -45 42 -66 31
-76 17 -135 -41 -173 -22 -15 -27 -15 -39 -3 -27 27 -45 185 -29 247 8 30 35
28 67 -5z m183 -477 c25 -54 34 -89 34 -126 0 -106 -35 -180 -85 -180 -37 0
-175 71 -193 99 -15 22 -15 26 3 57 26 42 185 224 197 224 5 0 25 -33 44 -74z
m-3880 -3271 c36 -36 64 -103 64 -153 0 -36 -5 -48 -32 -73 -64 -58 -86 -32
-95 117 -6 89 4 134 28 134 5 0 21 -11 35 -25z m4268 -177 c30 -50 25 -223 -8
-256 -13 -13 -18 -13 -41 2 -35 23 -83 92 -95 137 -19 71 29 139 97 139 23 0
37 -7 47 -22z m-4107 -250 c5 -7 22 -43 38 -80 25 -60 27 -76 22 -137 -7 -80
-27 -127 -62 -143 -20 -9 -37 -6 -99 21 -41 18 -87 46 -102 62 -27 28 -27 30
-12 62 9 18 45 65 81 104 36 39 73 83 82 97 19 28 37 33 52 14z"/>
                <path d="M28190 19677 c-13 -7 -35 -27 -47 -46 -40 -60 -41 -64 -68 -451 l-6
-85 74 210 c41 116 88 247 105 292 16 45 28 84 24 87 -10 10 -56 6 -82 -7z"/>
                <path d="M28286 19669 c-2 -8 -10 -77 -16 -154 -14 -167 -32 -258 -71 -343
-35 -78 -60 -111 -107 -139 -64 -39 -113 -27 -139 35 -13 29 -16 74 -15 212 1
96 -2 193 -6 215 l-7 39 -38 -82 c-56 -122 -71 -190 -71 -322 -1 -119 -2 -117
52 -204 35 -56 -25 -565 -103 -875 -77 -312 -99 -434 -100 -561 0 -111 1 -117
33 -174 34 -63 38 -85 21 -117 -14 -27 -50 -39 -144 -49 -117 -12 -140 -49
-90 -148 35 -70 55 -154 62 -258 10 -163 -17 -260 -77 -274 -48 -11 -55 -46
-53 -259 2 -220 -6 -256 -67 -302 -111 -82 -163 -184 -205 -401 -8 -40 -66
-93 -127 -116 -29 -11 -95 -25 -145 -31 -239 -29 -1131 -40 -1733 -21 -717 23
-836 -32 -885 -407 -21 -160 -36 -658 -24 -798 33 -377 214 -819 355 -865 76
-25 190 18 303 114 113 97 151 98 163 7 4 -34 14 -61 28 -76 68 -72 310 -54
655 49 602 180 744 212 768 174 22 -33 -106 -471 -222 -763 -52 -129 -490
-1017 -701 -1420 -60 -115 -114 -223 -120 -239 -9 -27 -9 -28 18 -21 74 19 98
17 127 -8 l28 -24 -31 -19 c-38 -22 -39 -25 -9 -51 35 -33 80 -51 148 -62 35
-6 80 -15 99 -21 45 -13 82 -5 110 26 34 35 42 30 38 -26 -3 -49 -3 -50 15
-27 76 97 434 654 887 1377 288 461 671 1046 841 1285 179 252 305 384 425
447 80 42 218 91 320 112 38 9 129 33 202 56 74 22 140 40 147 40 8 0 40 9 71
21 45 17 59 28 70 55 20 48 56 52 246 25 234 -33 296 -37 303 -19 9 22 -21 94
-65 160 -61 92 -52 128 32 128 132 0 183 -91 199 -355 21 -337 51 -522 164
-995 101 -429 194 -718 290 -911 59 -117 87 -149 132 -149 66 0 91 -32 58 -76
-21 -28 -18 -98 7 -157 63 -148 162 -282 335 -455 192 -190 283 -221 283 -95
0 81 -106 250 -181 289 -36 18 -99 18 -123 -1 -30 -23 -58 -18 -111 17 -48 32
-75 75 -75 122 0 36 14 39 47 13 43 -37 68 -47 112 -47 30 0 43 5 51 19 23 45
3 139 -46 214 -13 20 -20 37 -16 37 14 0 230 -206 277 -263 53 -66 136 -189
165 -246 38 -75 51 -123 60 -215 11 -110 29 -140 87 -144 45 -3 84 21 108 68
16 32 40 198 39 273 -1 64 -28 92 -96 101 -67 9 -87 21 -104 61 -20 50 -17 83
11 110 31 32 50 31 79 0 16 -18 34 -25 59 -25 49 0 54 23 19 79 -37 61 -112
106 -191 115 -41 5 -69 15 -95 34 -80 60 -172 214 -259 437 -127 322 -198 643
-282 1275 -80 596 -117 802 -160 906 -8 20 -15 40 -15 44 0 4 100 10 223 13
224 5 441 27 802 82 270 40 337 71 391 181 34 70 36 159 4 227 -80 169 -350
282 -755 317 -66 5 -263 10 -437 10 -282 0 -318 2 -318 16 0 8 18 207 40 442
55 593 63 738 64 1127 1 276 -3 362 -17 456 -42 279 -136 484 -333 730 -38 47
-139 173 -225 280 -424 528 -629 733 -944 942 -149 98 -204 126 -329 166 -123
40 -184 49 -190 30z m1552 -1934 c109 -52 109 -71 -5 -195 -43 -47 -87 -98
-99 -114 -41 -57 -70 -9 -81 134 -6 88 8 152 42 186 32 32 57 30 143 -11z m22
-520 c15 -18 26 -177 16 -230 -9 -47 -29 -44 -73 13 -37 49 -57 109 -47 147 6
24 66 85 83 85 5 0 14 -7 21 -15z m-186 -740 c9 -115 28 -336 42 -490 36 -389
28 -500 -39 -537 -14 -8 -65 -13 -142 -13 -66 0 -165 -4 -220 -9 -100 -9 -100
-9 -132 18 -44 37 -57 89 -44 175 16 100 56 232 109 356 52 121 393 729 403
717 3 -4 14 -101 23 -217z m-3403 -2086 c62 -13 99 -46 99 -87 0 -41 -67 -171
-95 -184 -31 -14 -66 6 -153 86 -39 35 -82 72 -96 81 -41 27 -31 44 47 80 78
36 117 41 198 24z m-460 -181 c60 -66 32 -89 -120 -95 -85 -4 -110 -2 -126 10
-20 16 -20 16 5 41 41 41 118 76 167 76 37 0 49 -5 74 -32z"/>
                <path d="M15427 19638 c-46 -49 -56 -88 -72 -298 -8 -102 -17 -207 -20 -235
-4 -36 20 23 84 205 123 353 124 356 128 343 9 -25 -28 -332 -47 -403 -40
-142 -101 -227 -180 -250 -42 -12 -75 2 -97 42 -15 27 -18 58 -19 183 -1 270
-2 291 -18 275 -25 -26 -86 -192 -97 -264 -6 -37 -9 -110 -7 -160 3 -82 6 -97
30 -127 14 -20 29 -55 33 -80 15 -101 -46 -563 -106 -804 -73 -290 -100 -433
-106 -562 -6 -120 -5 -123 24 -185 41 -85 46 -104 33 -127 -16 -31 -47 -43
-133 -52 -43 -4 -90 -15 -103 -24 -32 -21 -32 -70 1 -135 40 -79 58 -172 59
-305 1 -143 -15 -189 -76 -217 -32 -16 -38 -23 -48 -71 -6 -32 -10 -126 -8
-228 3 -167 2 -176 -20 -215 -13 -22 -45 -55 -73 -73 -32 -21 -60 -50 -79 -84
-30 -52 -84 -210 -95 -280 -9 -59 -68 -111 -152 -136 -140 -42 -235 -46 -1333
-47 -1002 -1 -1048 -2 -1121 -21 -182 -47 -249 -132 -285 -361 -21 -135 -38
-724 -24 -852 36 -342 203 -760 330 -825 69 -36 187 -7 280 70 80 65 132 101
156 109 32 10 54 -17 54 -68 0 -54 26 -90 78 -107 91 -31 328 -1 582 74 513
151 694 199 742 195 42 -3 43 -4 46 -39 6 -70 -105 -444 -215 -724 -60 -154
-474 -995 -670 -1364 -134 -251 -166 -318 -158 -331 3 -5 25 -2 50 6 42 15 46
15 85 -7 22 -12 40 -26 40 -29 0 -3 -16 -14 -36 -24 l-37 -17 22 -23 c29 -31
102 -66 138 -66 16 0 52 -7 79 -15 74 -22 116 -19 144 10 37 39 53 33 48 -20
-3 -45 -3 -45 15 -23 65 82 471 712 912 1418 298 477 734 1136 886 1340 124
168 253 295 346 343 100 52 191 85 308 112 94 22 260 68 420 118 58 18 72 27
84 52 8 17 19 33 25 37 20 14 132 8 268 -12 73 -11 161 -20 196 -20 60 0 62 1
62 25 0 34 -20 79 -64 144 -61 91 -42 139 52 128 50 -5 87 -29 120 -77 39 -58
53 -126 63 -315 10 -215 28 -352 75 -580 118 -571 247 -1019 364 -1260 75
-155 92 -175 153 -182 66 -8 76 -24 47 -77 -21 -39 -22 -47 -11 -93 38 -155
190 -365 419 -575 87 -80 133 -108 176 -108 54 0 58 86 10 183 -77 153 -192
233 -260 182 -31 -23 -76 -18 -126 15 -48 32 -68 68 -68 121 0 36 9 36 62 -1
120 -84 192 -21 139 120 -11 28 -31 67 -46 86 -14 19 -22 34 -18 34 5 0 61
-48 126 -106 230 -207 387 -465 387 -636 0 -106 47 -154 123 -127 34 11 46 23
64 59 21 44 43 179 43 267 0 76 -33 113 -100 113 -66 0 -110 45 -110 112 0 74
64 104 109 52 17 -19 30 -24 54 -22 45 4 48 40 8 95 -39 54 -105 92 -172 100
-72 8 -97 22 -155 86 -92 100 -199 326 -283 592 -87 278 -141 553 -212 1085
-79 590 -114 791 -160 897 -10 24 -15 43 -11 44 4 0 54 0 112 -1 169 -2 451
24 820 76 309 43 384 66 445 137 66 77 83 201 40 292 -72 151 -340 269 -694
305 -57 5 -262 10 -457 10 l-354 0 0 36 c0 20 16 203 35 407 46 495 53 591 65
937 23 657 -30 936 -239 1246 -33 49 -86 121 -118 160 -32 40 -129 160 -216
269 -329 408 -536 633 -727 784 -118 94 -273 200 -370 253 -96 53 -277 108
-377 115 l-83 6 -33 -35z m1678 -1916 c110 -52 110 -72 -2 -194 -43 -47 -90
-100 -105 -119 -26 -32 -28 -33 -42 -16 -27 35 -48 158 -37 225 10 65 33 117
53 125 31 13 77 5 133 -21z m27 -534 c19 -35 17 -236 -2 -248 -20 -12 -53 20
-85 84 -35 71 -33 110 9 152 40 40 60 43 78 12z m-182 -839 c16 -184 36 -417
45 -519 24 -263 10 -361 -54 -394 -22 -12 -60 -16 -148 -16 -65 0 -154 -5
-198 -11 -67 -10 -86 -9 -117 4 -72 30 -91 101 -64 238 21 103 66 236 118 349
39 86 380 691 386 686 2 -2 16 -154 32 -337z m-3362 -1993 c62 -32 61 -93 -3
-202 -34 -58 -54 -68 -93 -46 -29 15 -232 189 -232 198 0 6 101 55 138 68 43
15 145 5 190 -18z m-539 -140 c30 -16 61 -56 61 -80 0 -19 -50 -32 -145 -40
-143 -12 -181 22 -91 82 76 50 128 62 175 38z"/>
                <path d="M41493 18200 c-32 -13 -82 -152 -98 -276 -13 -99 -24 -135 -51 -162
-19 -18 -29 -62 -63 -267 -67 -401 -104 -712 -104 -875 0 -85 4 -179 8 -209 8
-60 -10 -197 -28 -220 -17 -20 -40 -129 -38 -176 1 -22 2 -85 1 -140 0 -72 5
-113 17 -145 l17 -45 8 59 c4 32 12 64 18 72 8 9 8 18 1 32 -8 15 -7 31 5 64
26 71 54 219 54 285 0 41 4 65 14 70 7 4 26 46 41 93 36 109 89 243 158 395
64 141 93 227 106 311 5 34 21 85 36 114 19 38 26 64 24 97 -3 36 2 51 25 79
15 19 38 39 50 44 17 6 25 19 30 48 17 106 -63 591 -115 698 -26 55 -67 74
-116 54z m-73 -1386 c0 -16 -49 -59 -57 -49 -5 4 -2 10 5 12 6 3 15 22 18 44
6 35 8 38 20 22 7 -10 14 -23 14 -29z"/>
                <path d="M27217 18178 c-34 -27 -80 -169 -92 -288 -6 -65 -31 -140 -46 -140
-22 0 -59 -191 -118 -620 -66 -475 -74 -606 -43 -715 9 -31 8 -60 -4 -135 -9
-52 -21 -102 -28 -110 -16 -19 -40 -135 -37 -175 1 -16 3 -82 4 -145 1 -84 6
-123 18 -144 l16 -29 7 56 c4 31 11 59 16 62 5 3 6 21 3 40 -3 21 1 52 10 78
22 59 57 236 57 290 0 25 4 47 9 49 5 2 27 57 49 124 22 66 77 202 121 302 94
213 118 278 130 365 5 35 21 88 37 118 19 38 27 68 26 102 -1 39 4 53 29 82
17 19 39 37 49 40 29 9 34 62 21 192 -29 289 -89 550 -136 590 -29 25 -72 30
-98 11z m-67 -1382 c0 -15 -32 -46 -47 -46 -12 0 -12 3 1 16 9 8 16 30 16 47
1 30 1 31 15 13 8 -11 15 -24 15 -30z"/>
                <path d="M14502 18173 c-40 -8 -96 -155 -107 -281 -7 -77 -30 -148 -51 -160
-21 -12 -56 -190 -109 -564 -59 -416 -78 -616 -65 -688 6 -30 13 -74 17 -98 7
-49 -13 -185 -32 -222 -8 -14 -21 -59 -30 -100 -13 -59 -15 -84 -6 -115 6 -21
8 -78 5 -129 -4 -77 -2 -97 15 -131 l19 -40 1 50 c1 28 8 63 16 79 12 22 13
32 3 43 -9 11 -7 29 10 81 35 108 52 197 52 267 0 45 4 65 13 65 6 0 20 28 30
63 29 99 82 235 153 394 82 182 101 237 118 334 8 41 26 99 41 129 21 42 26
63 22 96 -4 36 0 48 26 79 16 20 40 41 53 46 23 8 24 13 24 106 0 205 -79 610
-129 663 -28 31 -53 40 -89 33z m-91 -1374 c9 -18 7 -25 -15 -47 -14 -14 -29
-22 -33 -18 -4 5 -2 11 5 13 6 3 12 20 12 39 0 39 14 44 31 13z"/>
                <path d="M32503 17932 c-6 -4 -25 -42 -42 -86 -32 -79 -39 -131 -31 -231 3
-39 -1 -61 -19 -95 -28 -54 -35 -131 -51 -570 -17 -454 -3 -684 47 -805 27
-64 27 -59 4 -270 -5 -49 -14 -105 -20 -123 -9 -29 -7 -38 12 -64 19 -26 22
-42 22 -131 1 -105 7 -128 46 -162 18 -16 21 -17 17 -4 -15 40 -19 108 -9 128
9 15 9 27 1 41 -12 22 -2 115 25 232 7 33 9 60 3 66 -4 4 -11 31 -14 58 -5 39
-3 53 10 63 9 7 16 22 16 32 0 10 29 123 66 250 96 342 106 389 108 514 2 98
5 116 29 162 31 61 33 73 11 114 -15 29 -14 32 11 65 15 20 38 39 51 44 20 8
24 17 24 47 0 120 -167 645 -225 707 -24 26 -67 34 -92 18z m95 -1384 c14 -14
-12 -48 -37 -48 -12 0 -12 3 -3 12 7 7 12 20 12 30 0 21 11 23 28 6z"/>
                <path d="M23867 15863 c-4 -7 -27 -72 -49 -145 l-42 -133 -6 -235 c-6 -232 -7
-236 -38 -320 -61 -166 -65 -205 -69 -747 -4 -452 -3 -494 13 -518 16 -24 16
-35 4 -124 -11 -81 -11 -116 0 -197 7 -55 18 -102 24 -106 6 -4 25 -8 43 -8
71 0 131 91 222 339 23 63 65 168 92 233 54 129 59 162 30 178 -11 5 -35 31
-53 56 -28 39 -33 55 -33 103 0 31 -7 77 -15 103 -11 39 -12 58 -2 106 20 97
14 183 -23 357 -20 91 -47 231 -60 312 -14 81 -29 150 -33 153 -9 7 -9 96 1
106 15 15 7 120 -14 174 -29 78 -30 132 -1 163 20 21 23 33 20 93 -2 39 -6 64
-11 57z m39 -1118 c3 -13 0 -34 -7 -47 l-13 -23 -7 32 c-3 18 -12 39 -18 47
-9 11 -9 15 1 19 21 7 38 -4 44 -28z"/>
                <path d="M37002 12838 c-7 -7 -12 -18 -12 -26 0 -8 -7 -20 -16 -27 -26 -19 8
-81 57 -105 l39 -18 0 27 c0 29 -35 140 -48 153 -5 5 -13 3 -20 -4z"/>
                <path d="M37050 11336 c0 -8 -9 -20 -19 -26 -11 -5 -29 -26 -41 -46 -19 -29
-21 -40 -11 -60 14 -32 14 -77 0 -91 -8 -8 -8 -17 0 -31 6 -11 11 -32 11 -46
0 -21 -5 -26 -24 -26 -13 0 -26 -6 -29 -12 -6 -16 2 -544 9 -576 2 -12 21 -40
40 -62 30 -33 43 -40 74 -40 72 0 137 66 168 172 10 35 24 60 34 64 18 5 78
69 78 83 0 5 -15 12 -32 15 -39 8 -78 39 -103 83 -16 27 -17 36 -7 58 23 49
15 79 -39 146 -28 35 -59 80 -67 99 -9 19 -25 45 -35 56 -18 21 -18 22 1 40
39 38 42 55 16 90 -22 28 -24 37 -15 73 5 24 5 43 0 47 -5 3 -9 -2 -9 -10z"/>
                <path d="M22040 10922 c-38 -39 -50 -88 -50 -212 0 -116 11 -175 34 -198 13
-14 21 -2 76 109 54 109 62 134 67 199 5 67 3 78 -17 103 -29 37 -72 36 -110
-1z"/>
                <path d="M5115 8648 c-11 -6 -27 -15 -35 -20 -8 -4 -22 -11 -30 -14 -8 -3 -23
-10 -32 -15 -10 -5 -32 -9 -50 -9 -30 0 -43 -8 -105 -60 -7 -5 -19 -10 -28
-10 -8 0 -15 -7 -15 -15 0 -9 -7 -18 -15 -21 -8 -4 -15 -12 -15 -20 0 -8 -8
-14 -17 -14 -20 0 -43 -42 -43 -77 0 -13 -7 -26 -15 -29 -8 -4 -15 -13 -15
-21 0 -8 -9 -22 -20 -31 -20 -17 -20 -32 -29 -956 -10 -960 -9 -966 27 -989 6
-4 12 -15 12 -22 0 -8 5 -15 10 -15 6 0 10 -14 10 -30 0 -33 14 -50 95 -115
28 -22 58 -47 68 -56 10 -10 35 -20 54 -24 23 -4 37 -13 40 -26 4 -13 14 -19
34 -19 15 0 31 -4 34 -10 3 -5 24 -10 45 -10 21 0 42 -4 45 -10 3 -5 19 -10
34 -10 16 0 34 -6 40 -14 14 -17 55 -26 141 -32 60 -4 85 -11 132 -35 10 -5
25 -9 33 -9 8 0 15 -4 15 -10 0 -5 20 -10 45 -10 25 0 45 -4 45 -10 0 -6 31
-10 74 -10 41 0 78 -4 81 -10 3 -5 15 -10 25 -10 10 0 20 -5 22 -11 2 -6 34
-13 71 -16 37 -3 70 -10 73 -14 3 -5 43 -9 89 -9 46 0 87 -4 90 -10 3 -5 19
-10 34 -10 15 0 37 -9 49 -20 16 -15 35 -20 82 -20 33 0 60 -4 60 -10 0 -6 40
-10 100 -10 60 0 100 -4 100 -10 0 -5 16 -10 35 -10 19 0 35 -4 35 -10 0 -5 9
-10 19 -10 11 0 23 -4 26 -10 3 -5 43 -10 89 -10 45 0 86 -4 92 -9 5 -5 56
-11 114 -13 87 -3 112 -7 143 -26 32 -18 53 -22 127 -22 49 0 92 -4 95 -10 3
-6 52 -10 110 -10 58 0 107 -4 110 -10 3 -5 19 -10 36 -10 16 0 29 -4 29 -10
0 -5 16 -10 35 -10 19 0 35 -4 35 -10 0 -6 47 -10 120 -10 73 0 120 -4 120
-10 0 -6 40 -10 100 -10 60 0 100 -4 100 -10 0 -5 20 -10 45 -10 25 0 45 -4
45 -10 0 -5 16 -10 35 -10 19 0 35 -4 35 -10 0 -6 58 -10 155 -10 97 0 155 -4
155 -10 0 -6 33 -10 80 -10 44 0 88 -4 98 -10 26 -15 133 -29 292 -40 209 -14
452 -40 472 -51 9 -5 32 -9 52 -9 19 0 38 -4 41 -10 4 -6 77 -10 185 -10 108
0 181 -4 185 -10 3 -5 44 -10 90 -10 46 0 87 -4 90 -10 3 -5 24 -10 45 -10 21
0 42 -4 45 -10 3 -5 40 -10 81 -10 43 0 74 -4 74 -10 0 -6 71 -10 194 -10 119
0 197 -4 201 -10 3 -5 44 -10 90 -10 46 0 87 -4 90 -10 3 -5 24 -10 45 -10 21
0 42 -4 45 -10 3 -5 44 -10 90 -10 46 0 87 -4 90 -10 4 -6 85 -10 210 -10 125
0 206 -4 210 -10 3 -6 48 -10 100 -10 52 0 97 -4 100 -10 3 -5 24 -10 46 -10
21 0 39 -4 39 -10 0 -6 40 -10 100 -10 60 0 100 -4 100 -10 0 -6 86 -10 239
-10 149 0 242 -4 246 -10 3 -6 44 -10 90 -10 46 0 87 -4 90 -10 3 -5 24 -10
45 -10 21 0 42 -4 45 -10 4 -6 54 -10 119 -10 61 0 116 -4 122 -10 5 -5 114
-11 244 -14 297 -6 532 -26 554 -47 6 -5 65 -9 132 -9 68 0 125 -4 128 -8 3
-5 136 -12 296 -15 230 -5 301 -10 341 -23 52 -17 70 -37 50 -57 -6 -6 -11
-18 -11 -27 0 -27 -56 -70 -90 -70 -16 0 -30 -4 -30 -10 0 -5 -7 -10 -16 -10
-8 0 -30 -11 -48 -25 -18 -14 -40 -25 -49 -25 -10 0 -17 -4 -17 -10 0 -5 -15
-10 -33 -10 -24 0 -35 -6 -42 -22 -7 -15 -20 -24 -40 -26 -17 -2 -46 -16 -65
-32 -18 -15 -49 -31 -68 -35 -19 -3 -49 -20 -66 -36 -17 -16 -42 -32 -55 -36
-23 -6 -44 -20 -76 -50 -8 -7 -19 -13 -25 -13 -11 0 -67 -39 -104 -72 -11 -10
-35 -21 -54 -24 -21 -4 -36 -14 -39 -24 -4 -17 -26 -31 -66 -44 -9 -3 -20 -14
-24 -23 -9 -25 -55 -63 -75 -63 -12 0 -18 -8 -18 -21 0 -22 -33 -57 -82 -87
-16 -9 -28 -23 -28 -32 0 -8 -14 -24 -30 -35 -16 -11 -30 -26 -30 -33 0 -7 -6
-15 -14 -18 -8 -3 -16 -14 -19 -25 -3 -10 -12 -19 -21 -19 -9 0 -16 -7 -16
-15 0 -8 -4 -15 -10 -15 -5 0 -10 -7 -10 -15 0 -9 -7 -18 -15 -21 -8 -4 -15
-12 -15 -20 0 -8 -7 -14 -16 -14 -19 0 -44 -24 -44 -43 0 -8 -16 -28 -35 -44
-19 -17 -35 -35 -35 -40 0 -6 -11 -22 -25 -37 -14 -15 -25 -33 -25 -40 0 -8
-13 -16 -30 -19 -23 -5 -30 -12 -30 -30 0 -13 -15 -39 -32 -59 -42 -46 -58
-73 -58 -97 0 -11 -3 -21 -7 -23 -27 -12 -57 -118 -33 -118 6 0 10 -15 10 -34
0 -21 6 -36 15 -40 8 -3 15 -14 15 -25 0 -22 97 -111 123 -111 15 0 84 -27
129 -51 28 -15 104 -11 118 6 7 8 21 15 31 15 10 0 21 5 24 10 3 6 15 10 26
10 10 0 19 5 19 10 0 6 14 10 30 10 21 0 30 5 30 16 0 9 27 43 60 76 33 32 60
66 60 76 0 23 19 42 42 42 13 0 18 8 18 25 0 14 7 28 15 31 8 4 15 10 15 15 0
5 34 43 75 84 41 41 75 79 75 85 0 5 3 10 8 10 13 0 82 65 82 78 0 6 3 12 8
12 4 0 23 14 42 31 19 17 51 42 70 57 19 15 37 29 40 32 3 4 23 19 45 36 22
16 46 35 53 42 8 7 29 19 46 26 18 8 44 25 59 40 16 16 37 26 54 26 20 0 31 7
38 23 6 12 19 23 31 25 11 2 38 18 60 37 21 19 46 35 54 35 8 0 33 16 55 35
22 20 59 41 83 47 23 7 42 17 42 24 0 18 35 44 61 44 29 0 43 9 52 33 3 9 12
17 20 17 22 0 90 37 119 64 16 15 39 26 55 26 17 0 43 14 68 35 22 19 51 35
64 35 13 0 43 16 67 35 23 19 52 35 65 35 12 0 37 11 55 25 18 14 40 25 48 25
9 0 16 5 16 10 0 6 14 10 30 10 19 0 41 10 58 25 17 16 38 25 59 25 18 0 33 5
33 10 0 6 16 10 35 10 19 0 35 3 35 8 0 4 15 17 33 28 27 18 40 21 82 15 28
-4 56 -10 63 -14 17 -11 15 -56 -3 -63 -8 -4 -15 -15 -15 -25 0 -10 -34 -52
-75 -92 -47 -47 -75 -82 -75 -96 0 -11 -3 -21 -8 -21 -13 0 -82 -65 -82 -77 0
-7 -4 -13 -10 -13 -5 0 -10 -6 -10 -14 0 -7 -16 -24 -35 -37 -22 -15 -35 -32
-35 -46 0 -14 -19 -42 -45 -69 -25 -25 -45 -54 -45 -65 0 -10 -4 -19 -10 -19
-5 0 -10 -6 -10 -14 0 -7 -16 -24 -35 -37 -19 -13 -35 -31 -35 -40 0 -26 -24
-68 -45 -79 -22 -12 -45 -54 -45 -82 0 -10 -4 -18 -10 -18 -5 0 -10 -6 -10
-14 0 -7 -11 -21 -25 -30 -14 -9 -25 -23 -25 -31 0 -8 -4 -15 -10 -15 -5 0
-10 -16 -10 -35 0 -19 -4 -35 -10 -35 -5 0 -10 -6 -10 -14 0 -7 -11 -22 -25
-32 -14 -10 -25 -24 -25 -31 0 -7 -4 -13 -10 -13 -5 0 -10 -20 -10 -45 0 -25
-4 -45 -10 -45 -5 0 -10 -6 -10 -14 0 -7 -11 -21 -25 -30 -19 -12 -25 -25 -25
-51 0 -19 -4 -35 -10 -35 -6 0 -10 -27 -10 -60 0 -33 -4 -60 -10 -60 -5 0 -10
-11 -10 -25 0 -14 -7 -28 -15 -31 -8 -4 -15 -12 -15 -20 0 -8 -4 -14 -10 -14
-6 0 -10 -30 -10 -69 0 -39 -4 -73 -10 -76 -13 -8 -13 -242 0 -250 17 -10 12
-105 -5 -111 -12 -5 -15 -25 -15 -95 0 -90 -9 -122 -42 -147 -10 -7 -18 -21
-18 -31 0 -10 -7 -24 -15 -31 -8 -7 -15 -25 -15 -40 0 -19 -10 -35 -30 -51
-16 -13 -30 -31 -30 -40 0 -9 -7 -22 -15 -29 -8 -7 -15 -18 -15 -24 0 -6 -14
-22 -30 -35 -17 -13 -30 -31 -30 -41 0 -9 -11 -26 -25 -36 -14 -10 -25 -24
-25 -31 0 -7 -9 -13 -20 -13 -11 0 -20 -7 -20 -15 0 -7 -6 -18 -12 -22 -7 -4
-18 -17 -24 -28 -6 -11 -21 -21 -33 -23 -14 -2 -21 -11 -21 -27 0 -12 -10 -29
-22 -38 -36 -24 -64 -51 -70 -68 -4 -9 -20 -23 -37 -30 -17 -8 -31 -18 -31
-24 0 -5 -16 -21 -36 -35 -53 -38 -94 -77 -94 -89 0 -6 -4 -11 -9 -11 -4 0
-21 -12 -37 -27 -16 -16 -47 -41 -69 -58 -22 -16 -53 -42 -70 -57 -16 -16 -38
-31 -47 -34 -10 -3 -18 -13 -18 -23 0 -17 -21 -31 -63 -45 -11 -3 -21 -15 -24
-25 -2 -10 -17 -25 -31 -32 -30 -16 -61 -48 -62 -65 0 -6 -11 -17 -25 -24 -33
-17 -125 -104 -125 -117 0 -5 -11 -15 -24 -23 -13 -7 -27 -21 -29 -31 -3 -11
-12 -19 -21 -19 -9 0 -16 -7 -16 -15 0 -8 -4 -15 -9 -15 -5 0 -12 -9 -16 -19
-3 -11 -19 -26 -35 -33 -23 -10 -30 -20 -30 -40 0 -20 -14 -42 -45 -74 -33
-33 -45 -53 -45 -75 0 -16 -4 -29 -10 -29 -5 0 -10 -8 -10 -19 0 -10 -7 -24
-15 -31 -8 -7 -15 -23 -15 -36 0 -13 -4 -24 -10 -24 -14 0 -12 -111 3 -143 6
-15 15 -27 20 -27 4 0 7 -16 7 -35 0 -19 5 -35 10 -35 6 0 10 -11 10 -24 0
-15 12 -33 33 -49 17 -13 45 -36 61 -50 19 -17 51 -29 91 -36 77 -14 172 -14
180 -1 3 5 14 10 24 10 10 0 26 9 36 20 11 12 37 22 66 26 27 4 49 12 49 18 0
14 36 46 51 46 10 0 67 40 103 72 11 10 24 18 28 18 5 0 19 11 32 25 13 14 31
25 40 25 9 0 16 6 16 14 0 8 15 25 33 37 17 13 34 26 37 29 3 3 23 19 45 36
52 38 65 50 65 63 0 5 15 20 33 32 42 31 57 45 57 58 0 6 16 21 35 33 19 13
35 30 35 38 0 8 9 21 20 28 11 7 20 19 20 27 0 9 9 15 21 15 12 0 23 8 26 20
3 11 9 20 14 20 5 0 9 4 9 9 0 5 20 31 45 57 25 25 45 50 45 54 0 4 20 28 45
53 25 26 45 52 45 59 0 7 14 22 30 33 19 12 30 29 30 43 0 12 7 25 15 28 8 4
15 11 15 18 0 6 14 22 30 35 17 13 30 29 30 36 0 7 7 15 15 19 8 3 15 11 15
18 0 8 9 20 20 28 11 8 20 20 20 27 0 7 5 13 10 13 6 0 10 7 10 15 0 9 7 18
15 21 8 4 15 12 15 20 0 7 8 19 18 26 22 17 42 53 42 77 0 10 7 21 15 25 8 3
15 11 15 18 0 7 14 22 30 33 18 12 30 29 30 42 0 12 15 37 33 56 42 46 57 72
57 100 0 15 13 34 35 51 19 16 35 34 35 42 0 8 5 14 10 14 6 0 10 16 10 35 0
19 5 35 10 35 6 0 10 6 10 14 0 7 11 22 25 32 14 10 25 24 25 31 0 7 5 13 10
13 6 0 10 16 10 35 0 19 5 35 10 35 6 0 10 7 10 15 0 9 6 18 13 21 8 3 20 20
27 39 8 19 17 35 22 35 4 0 8 18 8 40 0 41 7 54 48 92 12 13 22 34 22 49 0 15
5 31 10 34 6 3 10 40 10 81 0 57 4 76 15 80 10 4 15 20 15 50 0 24 3 44 8 44
12 0 32 52 32 86 0 18 4 36 10 39 5 3 10 21 10 39 0 19 7 39 15 46 8 7 15 18
15 24 0 6 9 18 20 26 13 10 20 26 20 50 0 19 7 44 15 54 8 11 15 29 15 40 0
12 14 32 30 45 21 16 30 32 30 52 0 17 6 32 15 35 8 4 15 17 15 30 0 13 5 24
10 24 6 0 10 8 10 18 0 9 9 23 20 30 13 8 20 23 20 42 0 18 6 33 15 36 8 4 15
12 15 20 0 7 8 19 18 26 20 16 42 52 42 72 0 7 7 19 15 26 8 7 15 20 15 30 0
10 13 28 30 41 22 18 30 33 30 55 0 19 6 34 15 38 8 3 15 11 15 18 0 7 9 19
20 26 11 7 20 19 20 27 0 8 5 15 10 15 6 0 10 11 10 24 0 14 14 39 33 59 37
40 57 71 57 91 0 8 16 27 35 42 19 14 35 33 35 40 0 8 5 14 10 14 6 0 10 11
10 24 0 14 14 39 33 59 37 40 57 71 57 91 0 8 16 27 35 42 19 14 35 33 35 40
0 8 5 14 10 14 6 0 10 10 10 23 0 13 14 32 33 46 32 24 57 62 57 87 0 7 16 25
35 40 19 14 35 33 35 40 0 8 5 14 10 14 6 0 10 11 10 24 0 16 12 31 35 47 19
13 35 29 35 36 0 6 8 18 18 25 10 7 21 21 25 31 3 9 16 17 27 17 11 0 20 5 20
10 0 6 32 10 78 10 73 0 79 -2 112 -33 38 -36 60 -69 60 -91 0 -8 11 -23 25
-34 14 -11 25 -29 25 -41 0 -12 5 -21 10 -21 6 0 10 -20 10 -45 0 -25 5 -45
10 -45 6 0 10 -7 10 -16 0 -9 11 -25 25 -36 14 -11 23 -23 20 -28 -3 -4 2 -10
10 -14 10 -3 15 -19 15 -44 0 -30 6 -45 28 -63 33 -29 52 -62 52 -89 0 -11 3
-20 8 -20 12 0 82 -64 82 -75 0 -6 12 -19 27 -30 28 -21 88 -71 112 -93 20
-19 88 -52 107 -52 9 0 18 -9 21 -20 5 -19 14 -20 127 -20 115 0 122 1 148 25
17 15 39 25 58 25 16 0 30 3 30 8 0 4 16 18 35 31 19 13 45 32 57 43 13 11 38
32 56 45 17 14 32 32 32 39 0 8 5 14 10 14 6 0 10 7 10 15 0 8 16 25 35 37 19
13 35 31 35 41 0 22 20 58 43 75 9 7 17 19 17 26 0 8 7 16 15 20 8 3 15 17 15
31 0 30 18 64 43 83 9 7 17 20 17 28 0 8 7 17 15 20 11 4 15 21 15 55 0 27 5
49 10 49 6 0 10 8 10 18 0 9 9 23 20 30 13 8 20 23 20 42 0 18 6 33 15 36 11
5 15 22 15 64 0 33 5 62 10 65 5 3 10 17 10 31 0 13 5 24 10 24 6 0 10 7 10
15 0 8 4 15 9 15 6 0 13 20 17 45 3 25 10 48 15 51 5 3 9 35 9 72 0 66 1 67
37 92 36 25 40 25 257 28 122 2 646 -1 1166 -6 520 -5 950 -6 956 -1 14 11
259 14 1611 19 623 3 1133 8 1133 13 0 4 81 7 179 7 118 0 182 4 186 11 5 7
43 10 106 8 54 -2 102 0 105 4 4 4 125 7 269 7 144 0 265 3 269 8 4 4 193 5
421 3 265 -2 415 0 418 7 4 5 88 13 189 17 101 4 185 11 188 15 3 5 257 12
565 15 343 5 565 11 572 17 7 6 42 8 78 6 36 -2 65 0 65 4 0 4 36 8 80 8 44 0
80 4 80 9 0 5 217 12 483 16 265 4 487 11 493 16 6 5 72 12 148 16 75 3 136 9
136 13 0 4 194 11 430 15 237 4 433 11 436 16 3 5 22 9 43 9 20 0 42 5 48 11
7 7 109 13 294 16 171 3 293 9 306 15 14 7 82 9 193 6 94 -3 173 -1 176 3 3 5
18 9 34 9 16 0 32 5 35 10 3 6 39 10 80 10 41 0 77 5 80 10 5 8 75 9 219 4
156 -5 215 -4 220 5 5 7 52 11 145 11 78 0 141 4 147 10 5 5 59 13 119 16 61
4 115 10 120 14 6 4 156 10 335 14 196 4 326 10 328 16 2 6 23 10 46 10 23 0
47 5 53 11 8 8 96 13 270 16 142 2 267 8 278 13 18 9 150 19 248 20 20 0 37 5
37 10 0 6 35 10 80 10 44 0 80 4 80 8 0 5 131 12 292 15 176 4 296 11 302 17
5 5 22 10 36 10 14 0 31 5 37 11 6 6 107 12 267 16 141 3 259 10 262 15 3 4
38 8 78 8 39 0 77 5 83 11 6 6 57 13 114 16 57 2 113 8 124 13 11 5 128 11
260 13 159 4 241 9 243 16 2 6 19 11 38 11 19 0 34 5 34 10 0 6 36 10 88 10
49 0 92 3 96 7 4 4 79 6 167 4 110 -2 159 0 159 8 0 7 24 11 63 11 35 0 68 5
74 11 7 7 62 14 133 17 66 3 123 9 126 13 3 5 79 9 169 9 90 0 166 4 169 9 3
4 37 11 76 15 38 4 72 11 75 17 4 5 55 9 116 9 72 0 109 4 109 11 0 8 46 10
149 8 83 -2 153 0 157 4 4 4 20 7 35 7 15 0 31 5 34 10 3 6 19 10 34 10 15 0
31 4 37 10 5 5 116 12 247 16 130 4 237 11 237 16 0 4 13 8 28 8 15 0 33 5 38
10 6 6 86 13 182 17 95 3 172 10 172 15 0 4 49 8 108 8 63 0 112 4 119 11 5 5
36 13 67 16 31 3 59 10 62 14 3 5 63 9 133 9 70 0 131 4 137 9 5 5 57 11 116
13 67 2 112 8 120 16 6 6 29 12 49 12 21 0 40 4 43 8 3 5 96 12 208 16 111 3
207 11 212 16 6 6 22 10 36 10 14 0 31 5 37 11 6 6 82 13 187 16 97 3 179 10
182 15 3 4 31 8 63 8 33 0 72 7 87 15 16 8 48 15 72 15 23 0 42 5 42 10 0 6
52 10 135 10 74 0 135 4 135 9 0 5 36 11 80 13 45 3 86 10 92 16 7 7 36 12 65
12 29 0 53 4 53 9 0 9 180 20 322 20 25 0 49 5 53 11 3 5 15 10 26 10 10 0 19
5 19 10 0 6 24 10 54 10 30 0 57 4 60 8 3 5 76 12 163 15 99 3 159 9 161 16 2
6 19 11 37 11 18 0 35 5 37 11 2 7 64 13 176 17 95 3 175 9 178 13 3 5 15 9
28 9 12 0 42 9 65 21 29 14 58 20 92 18 27 -2 52 0 55 4 4 4 52 7 106 7 59 0
98 4 98 10 0 6 13 10 28 10 15 0 33 5 39 11 5 5 36 13 67 16 31 3 59 10 62 14
3 5 59 9 124 9 65 0 121 4 124 9 3 5 33 12 66 16 33 4 60 11 60 16 0 5 32 9
70 9 40 0 70 4 70 10 0 6 32 10 76 10 72 0 174 24 182 43 2 4 28 7 57 7 30 0
57 5 60 10 4 6 57 10 125 10 92 0 122 3 134 15 8 8 26 15 40 15 14 0 28 5 31
10 3 6 35 10 70 10 35 0 67 5 70 10 3 6 32 10 64 10 35 0 65 6 77 15 10 8 27
15 37 15 9 0 17 4 17 9 0 11 114 21 222 20 65 0 81 3 100 20 12 12 34 21 49
21 15 0 30 4 33 9 3 4 56 12 117 16 90 5 116 11 132 26 12 10 29 19 38 19 10
0 20 4 23 9 3 5 57 12 120 16 94 6 118 10 132 26 9 10 25 19 35 19 10 0 20 4
23 8 3 5 46 12 96 15 71 5 95 11 112 27 12 11 30 20 39 20 10 0 20 4 23 9 3 4
40 11 83 15 57 4 82 11 98 26 12 11 29 20 38 20 9 0 19 5 22 10 3 6 23 10 44
10 26 0 47 8 67 25 33 29 85 55 108 55 9 0 16 7 16 15 0 7 6 18 13 22 6 4 19
22 27 38 8 17 23 36 33 43 9 7 17 19 17 26 0 8 7 16 15 20 8 3 15 17 15 31 0
14 5 25 10 25 6 0 10 7 10 15 0 14 12 19 33 16 4 0 7 6 7 14 0 9 7 18 15 21 8
4 15 15 15 25 0 26 19 58 53 87 15 13 27 30 27 38 0 8 16 29 35 47 19 17 35
40 35 49 0 10 20 38 45 64 25 25 45 54 45 65 0 10 5 19 10 19 6 0 10 6 10 13
0 7 16 26 35 41 19 16 35 34 35 42 0 8 5 14 10 14 6 0 10 8 10 19 0 10 16 32
35 48 19 17 35 35 35 42 0 6 20 31 45 57 25 25 45 54 45 65 0 10 5 19 10 19 6
0 10 6 10 14 0 7 16 24 35 37 28 19 35 31 35 57 0 22 -5 32 -15 32 -8 0 -15
-4 -15 -10 0 -5 -9 -10 -19 -10 -11 0 -23 -4 -26 -10 -3 -6 -48 -10 -100 -10
-52 0 -96 -4 -99 -9 -3 -4 -30 -11 -59 -14 -30 -3 -63 -10 -73 -15 -51 -26
-81 -31 -227 -35 -98 -3 -163 -9 -170 -16 -6 -6 -19 -11 -29 -11 -10 0 -18 -4
-18 -10 0 -5 -20 -10 -44 -10 -25 0 -47 -4 -50 -9 -3 -4 -71 -11 -152 -15
-122 -6 -150 -10 -166 -26 -26 -24 -84 -31 -250 -29 -94 1 -138 -3 -138 -10 0
-6 -19 -11 -45 -11 -25 0 -45 -4 -45 -10 0 -5 -8 -10 -18 -10 -10 0 -23 -5
-29 -11 -7 -7 -73 -13 -168 -17 -85 -3 -158 -9 -161 -13 -3 -5 -27 -9 -53 -9
-27 0 -62 -7 -77 -15 -17 -8 -56 -15 -91 -15 -34 0 -65 -5 -68 -10 -4 -6 -60
-10 -135 -10 -71 0 -131 -4 -134 -9 -3 -4 -28 -11 -56 -15 -27 -4 -50 -11 -50
-16 0 -6 -20 -10 -45 -10 -25 0 -45 -4 -45 -9 0 -4 -98 -10 -217 -13 -153 -3
-218 -8 -221 -16 -2 -7 -15 -12 -28 -12 -13 0 -24 -4 -24 -9 0 -11 -135 -20
-312 -20 -85 1 -128 -3 -128 -10 0 -6 -16 -11 -35 -11 -19 0 -35 -4 -35 -10 0
-5 -20 -10 -44 -10 -25 0 -48 -5 -52 -11 -4 -7 -32 -9 -85 -4 -55 5 -79 4 -79
-4 0 -7 -43 -11 -135 -11 -83 0 -135 -4 -135 -10 0 -5 -15 -10 -34 -10 -19 0
-38 -5 -42 -11 -4 -7 -21 -9 -40 -6 -19 3 -34 2 -34 -4 0 -5 -54 -9 -125 -9
-69 0 -125 -4 -125 -9 0 -5 -61 -11 -135 -13 -90 -2 -138 -7 -147 -15 -6 -7
-36 -13 -65 -13 -29 0 -53 -4 -53 -9 0 -4 -123 -12 -272 -16 -150 -4 -274 -11
-276 -16 -2 -5 -11 -9 -21 -9 -9 0 -21 -4 -27 -9 -16 -15 -93 -22 -245 -20
-97 0 -149 -3 -153 -10 -5 -7 -50 -11 -125 -11 -69 0 -121 -4 -128 -11 -6 -6
-45 -13 -87 -16 -42 -3 -79 -10 -82 -14 -3 -5 -83 -9 -179 -9 -120 0 -176 -4
-181 -11 -5 -8 -38 -10 -110 -6 -63 4 -104 2 -104 -4 0 -5 -16 -9 -35 -9 -19
0 -35 -4 -35 -10 0 -6 -28 -10 -64 -10 -38 0 -68 -5 -72 -11 -5 -9 -56 -10
-184 -5 -124 5 -180 4 -185 -3 -4 -7 -50 -11 -124 -11 -71 0 -121 -4 -128 -11
-6 -6 -56 -13 -112 -16 -56 -3 -104 -10 -107 -14 -3 -5 -92 -9 -199 -9 -135 0
-196 -3 -201 -11 -5 -8 -37 -10 -110 -6 -64 4 -105 3 -109 -3 -3 -6 -19 -10
-36 -10 -16 0 -29 -4 -29 -10 0 -6 -40 -10 -99 -10 -55 0 -102 -4 -106 -9 -3
-6 -96 -7 -229 -4 -163 4 -225 3 -230 -5 -5 -8 -41 -12 -100 -12 -52 0 -97 -5
-103 -11 -6 -6 -70 -13 -149 -16 -77 -2 -148 -8 -159 -13 -11 -5 -168 -11
-349 -13 -218 -3 -332 -9 -339 -16 -6 -6 -32 -11 -57 -11 -25 0 -51 -5 -56
-10 -7 -7 -152 -13 -395 -16 -241 -4 -392 -10 -406 -16 -14 -7 -54 -9 -108 -4
-55 4 -85 3 -85 -4 0 -5 -24 -10 -54 -10 -30 0 -56 -4 -58 -10 -2 -6 -169 -12
-453 -16 -247 -4 -454 -10 -460 -14 -5 -4 -75 -11 -155 -14 -80 -4 -146 -12
-148 -17 -2 -5 -85 -9 -191 -9 -103 0 -191 -3 -195 -7 -4 -5 -157 -6 -341 -4
-217 2 -335 0 -339 -7 -3 -5 -77 -13 -163 -17 -87 -4 -163 -11 -168 -15 -6 -5
-276 -12 -600 -16 -325 -3 -595 -10 -600 -15 -6 -4 -97 -11 -203 -15 -115 -4
-192 -11 -192 -17 0 -6 -119 -8 -340 -5 -188 3 -343 2 -345 -2 -2 -4 -217 -9
-477 -12 -337 -4 -473 -9 -476 -17 -2 -7 -50 -11 -146 -11 -79 0 -145 -2 -147
-4 -2 -2 -416 -7 -919 -11 -503 -4 -919 -10 -923 -15 -5 -4 -48 -5 -96 -2 -60
3 -90 1 -95 -7 -5 -8 -71 -11 -215 -11 -114 0 -211 -4 -216 -8 -6 -4 -323 -5
-705 -2 -431 4 -700 2 -708 -3 -8 -6 -278 -7 -718 -3 -555 6 -708 5 -720 -5
-11 -9 -61 -11 -204 -6 -118 3 -190 2 -190 -4 0 -6 -163 -9 -428 -9 -235 1
-435 -2 -446 -6 -10 -4 -878 1 -1929 10 -1052 9 -1977 16 -2057 16 l-145 0
-34 29 c-18 16 -31 35 -29 42 3 8 -6 22 -19 32 -13 10 -23 29 -23 43 0 13 -4
24 -10 24 -6 0 -10 26 -10 59 0 33 -5 63 -10 66 -5 3 -10 17 -10 31 0 13 -4
24 -10 24 -5 0 -10 7 -10 15 0 8 -3 15 -7 15 -15 0 -43 62 -43 95 0 19 -5 37
-10 40 -5 3 -10 14 -10 24 0 10 -9 26 -20 36 -11 10 -20 24 -20 32 0 7 -4 13
-10 13 -5 0 -10 15 -10 34 0 21 -6 36 -15 40 -8 3 -15 12 -15 20 0 8 -8 21
-17 28 -23 17 -43 53 -43 75 0 10 -16 29 -35 42 -19 13 -35 29 -35 35 0 6 -14
22 -30 35 -17 13 -30 29 -30 37 0 8 -9 14 -20 14 -11 0 -24 8 -27 18 -10 24
-23 37 -63 55 -19 10 -49 31 -67 47 -27 25 -40 30 -82 30 -28 0 -51 5 -51 10
0 6 -22 10 -50 10 -27 0 -50 -4 -50 -10 0 -5 -23 -10 -50 -10 -40 0 -56 -5
-78 -25 -15 -14 -37 -25 -49 -25 -13 0 -23 -4 -23 -10 0 -5 -8 -10 -19 -10
-10 0 -31 -16 -45 -35 -15 -19 -36 -35 -47 -35 -11 0 -19 -7 -19 -16 0 -8 -25
-39 -55 -69 -30 -29 -55 -60 -55 -69 0 -9 -7 -19 -15 -22 -8 -4 -15 -14 -15
-23 0 -9 -9 -22 -20 -29 -11 -7 -20 -19 -20 -27 0 -8 -4 -15 -10 -15 -5 0 -10
-13 -10 -29 0 -16 -7 -34 -15 -41 -8 -7 -15 -18 -15 -24 0 -6 -9 -17 -20 -24
-11 -7 -20 -23 -20 -37 0 -14 -4 -25 -10 -25 -5 0 -10 -13 -10 -29 0 -16 -7
-34 -15 -41 -8 -7 -15 -21 -15 -31 0 -10 -9 -24 -20 -31 -11 -7 -20 -23 -20
-35 0 -12 -4 -25 -10 -28 -5 -3 -10 -24 -10 -46 0 -26 -5 -42 -15 -45 -8 -4
-15 -12 -15 -20 0 -8 -9 -14 -20 -14 -11 0 -20 -4 -20 -10 0 -5 -9 -10 -19
-10 -11 0 -23 -4 -26 -10 -8 -13 -471 -12 -650 2 -71 5 -195 11 -275 14 -80 3
-149 9 -154 15 -6 5 -78 9 -160 9 l-151 0 -13 -27 c-6 -15 -12 -33 -12 -40 0
-8 -7 -16 -15 -19 -13 -5 -15 -36 -15 -195 0 -119 -4 -189 -10 -189 -6 0 -10
-30 -10 -70 0 -40 -4 -70 -10 -70 -5 0 -10 -6 -10 -14 0 -7 -11 -22 -25 -32
-14 -10 -25 -29 -25 -41 0 -13 -4 -23 -10 -23 -5 0 -10 -7 -10 -15 0 -8 -16
-25 -35 -37 -19 -13 -35 -31 -35 -40 0 -11 -8 -18 -19 -18 -11 0 -23 -4 -26
-10 -3 -5 -14 -10 -24 -10 -10 0 -31 -11 -47 -25 -16 -14 -41 -25 -56 -25 -15
0 -30 -4 -33 -10 -8 -14 -237 -13 -327 1 -85 13 -119 27 -126 52 -2 9 -13 17
-23 17 -11 0 -19 5 -19 10 0 6 -9 10 -20 10 -11 0 -20 6 -20 14 0 8 -15 25
-32 37 -56 40 -78 73 -78 117 0 19 -7 33 -22 41 -13 6 -29 24 -35 38 -16 33
-17 170 -2 175 6 2 13 32 16 66 3 34 10 62 14 62 5 0 9 11 9 25 0 14 5 25 10
25 6 0 10 48 10 124 l0 125 -47 6 c-27 3 -59 8 -73 11 -14 3 -45 8 -70 10 -25
3 -54 9 -65 14 -11 5 -101 11 -200 13 -99 3 -271 12 -383 20 -112 9 -210 13
-218 10 -8 -3 -14 -16 -14 -28 0 -13 -4 -27 -10 -30 -5 -3 -10 -28 -10 -56 0
-27 -4 -49 -10 -49 -5 0 -10 -23 -10 -50 0 -36 -4 -52 -15 -56 -12 -5 -15 -32
-15 -160 0 -144 -6 -184 -31 -204 -4 -3 -18 -14 -33 -25 -14 -11 -26 -28 -26
-38 0 -10 -6 -24 -12 -30 -32 -30 -88 -67 -102 -67 -8 0 -18 -4 -21 -10 -3 -5
-14 -10 -24 -10 -10 0 -21 -8 -23 -18 -4 -13 -18 -21 -44 -25 -65 -11 -384 -8
-384 3 0 6 -16 10 -35 10 -23 0 -35 5 -35 14 0 7 -10 17 -22 21 -13 5 -30 11
-39 14 -24 9 -109 83 -109 95 0 6 -16 22 -35 36 -30 22 -34 31 -35 68 0 23 -4
42 -10 42 -13 0 -14 302 -1 315 5 6 11 90 15 187 l6 177 -157 5 c-215 8 -316
16 -327 27 -6 5 -27 9 -47 9 -21 0 -41 5 -44 10 -3 6 -28 10 -55 10 -27 0 -52
5 -55 10 -4 6 -68 10 -161 10 -102 0 -161 4 -173 12 -32 20 -51 -5 -51 -69 0
-38 -4 -55 -15 -59 -12 -5 -15 -30 -15 -140 0 -83 -4 -134 -10 -134 -5 0 -10
-20 -10 -45 0 -25 -4 -45 -10 -45 -5 0 -10 -13 -10 -29 0 -16 -7 -34 -15 -41
-10 -8 -15 -30 -15 -61 0 -27 -4 -49 -10 -49 -5 0 -10 -7 -10 -15 0 -8 -11
-22 -25 -31 -14 -9 -25 -21 -25 -26 0 -6 -8 -18 -17 -27 -10 -9 -23 -25 -29
-36 -6 -11 -23 -21 -38 -23 -14 -2 -26 -8 -26 -13 0 -5 -7 -9 -16 -9 -9 0 -29
-11 -46 -25 -16 -14 -38 -25 -49 -25 -10 0 -19 -4 -19 -10 0 -6 -66 -10 -179
-10 -109 0 -182 4 -186 10 -3 6 -13 10 -21 10 -8 0 -25 9 -37 20 -12 12 -33
23 -47 26 -41 9 -73 28 -78 47 -2 9 -11 17 -19 17 -18 0 -33 16 -53 53 -8 16
-29 44 -47 64 l-33 34 0 240 c0 204 2 240 15 245 12 5 15 24 15 85 0 46 4 79
10 79 6 0 10 15 10 34 0 19 5 38 10 41 19 12 11 45 -10 45 -11 0 -20 5 -20 10
0 6 -31 10 -74 10 -41 0 -78 5 -81 10 -3 6 -28 10 -54 10 -26 0 -52 5 -58 11
-6 6 -42 13 -80 16 -37 2 -77 8 -88 13 -11 5 -74 11 -140 14 -154 8 -222 18
-264 39 -19 9 -56 17 -82 17 -29 0 -49 5 -52 13 -3 10 -9 10 -26 0 -11 -7 -32
-13 -46 -13 -23 0 -25 -3 -25 -50 0 -27 -4 -50 -8 -50 -5 0 -12 -93 -16 -207
-4 -116 -11 -209 -16 -211 -6 -2 -10 -44 -10 -93 0 -53 -4 -89 -10 -89 -5 0
-10 -7 -10 -15 0 -8 -4 -15 -10 -15 -5 0 -10 -7 -10 -15 0 -9 -7 -18 -15 -21
-9 -4 -15 -19 -15 -38 0 -22 -8 -37 -26 -51 -14 -10 -35 -27 -46 -37 -12 -10
-25 -18 -30 -18 -4 0 -8 -9 -8 -20 0 -13 -7 -20 -19 -20 -11 0 -23 -4 -26 -10
-3 -5 -13 -10 -21 -10 -9 0 -30 -11 -48 -25 -18 -14 -40 -25 -49 -25 -10 0
-17 -4 -17 -10 0 -6 -60 -10 -160 -10 -88 0 -169 5 -180 10 -11 6 -29 15 -40
20 -11 6 -24 10 -30 10 -6 0 -20 9 -32 20 -12 11 -28 20 -36 20 -7 0 -19 6
-26 13 -7 6 -30 26 -50 42 -69 54 -76 62 -76 84 0 11 -4 21 -10 21 -5 0 -10 7
-10 16 0 9 -11 25 -25 36 -16 13 -25 29 -25 48 0 16 -4 32 -10 35 -17 10 -12
131 5 145 11 9 15 33 15 86 0 40 5 76 10 79 6 3 10 19 10 35 0 16 5 32 10 35
6 3 10 50 10 104 0 75 4 101 15 111 12 10 15 37 15 121 0 124 9 113 -105 123
-92 9 -147 20 -184 39 -23 12 -59 17 -112 17 -43 0 -81 5 -84 10 -3 6 -44 10
-91 10 -49 0 -84 4 -84 10 0 6 -16 10 -35 10 -19 0 -35 5 -35 10 0 6 -9 10
-19 10 -11 0 -23 5 -26 10 -3 6 -48 10 -100 10 -52 0 -97 4 -100 10 -3 6 -26
10 -51 10 -44 0 -44 0 -44 -35 0 -19 -4 -35 -9 -35 -4 0 -11 -55 -15 -122 -4
-68 -12 -127 -17 -133 -5 -5 -9 -26 -9 -46 0 -21 -4 -41 -10 -44 -5 -3 -10
-33 -10 -65 0 -35 -5 -60 -11 -62 -8 -3 -13 -60 -17 -171 -3 -92 -9 -170 -13
-173 -5 -3 -9 -13 -9 -22 0 -9 -16 -34 -35 -55 -19 -21 -35 -45 -35 -53 0 -8
-4 -14 -10 -14 -5 0 -10 -7 -10 -15 0 -8 -8 -15 -17 -15 -23 -1 -53 -23 -53
-40 0 -19 -31 -39 -61 -40 -14 0 -39 -11 -55 -25 -16 -14 -37 -25 -46 -25 -9
0 -20 -4 -23 -10 -7 -12 -120 -12 -256 -1 -74 6 -103 12 -127 29 -16 12 -37
22 -46 22 -9 0 -16 5 -16 10 0 6 -7 10 -15 10 -22 0 -114 72 -115 90 0 8 -16
26 -35 40 -27 20 -35 32 -35 58 0 17 -4 32 -10 32 -5 0 -10 20 -10 45 0 25 -4
45 -10 45 -5 0 -10 24 -10 54 0 30 5 58 10 61 6 3 10 43 10 89 0 52 5 87 13
94 8 9 13 72 15 222 2 118 8 210 13 210 5 0 9 36 9 80 0 47 4 80 10 80 14 0
12 66 -2 92 -25 43 -35 48 -102 48 -73 0 -256 28 -270 41 -6 5 -22 9 -37 9
-15 0 -31 5 -34 10 -3 6 -48 10 -100 10 -52 0 -97 4 -100 10 -3 6 -24 10 -45
10 -21 0 -42 5 -45 10 -3 6 -15 10 -26 10 -10 0 -19 5 -19 10 0 6 -20 10 -45
10 -25 0 -45 5 -45 10 0 14 -259 12 -285 -2z"/>
            </g>
        </svg>
    );
}
export default LogoSVG;