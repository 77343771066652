import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {Login} from "../../Components/Login";
import {CafesList} from "./CafesList";
import {UserDetails} from "../Users/UserDetails";
import {CafeDetails} from "./CafeDetails";

export const Cafes: React.FC = () => {
    return (
        <Routes>
            <Route path="list" element={<CafesList/>}/>
            <Route path="add" element={<Login/>}/>
            <Route path="details/:cafeId" element={<CafeDetails/>}/>
        </Routes>
    )
};