import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {apiCall} from "../../API/ApiCall";
import {ICafeDetailsItem} from "../Cafes/CafeDetailsItem";
import {getCookie} from "../../Utils/CookieController";
import axios from 'axios';

export const LinkTool: React.FC = () => {
    let server = "eu3";
    const [license, setLicense] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    
    function onChangeLicense(event: React.ChangeEvent<HTMLInputElement>) {
        setLicense(event.target.value);
    }
    function onChangeUsername(event: React.ChangeEvent<HTMLInputElement>) {
        setUsername(event.target.value);
    }
    function onChangePassword(event: React.ChangeEvent<HTMLInputElement>) {
        setPassword(event.target.value);
    }
    function login() {
        apiCall.get("/CRM/GetIcafeGoogleToken?server=" + server, {}).then(response => {
            console.log(response.data)
            axios.create({
                baseURL: `https://${server}.icafecloud.com/api/v2/auth/login`,
                headers: {
                    'Content-Type': 'application/json',
                }
            }).post("", {licenseName: license, staffName: username, staffPassword: password, google_token: response.data, lancode: "en"}).then((res) => {
                if (res.data.code != 200) {
                    server = res.data.message.split('.')[0].slice(8);
                    login();
                }
                else {
                    alert(`Ваш сервер: ${res.data.data.license.license_server_code}, ваш номер клуба: ${res.data.data.license.object_id}`)
                }
            });
        }).catch(e => {
            console.log(e);
        });
    }
    
    useEffect(() => {
        setTimeout(() => {
            const iframe = document.getElementById("myIframe");
            // @ts-ignore
            iframe.contentWindow.document.getElementsByName("license_name")[0].addEventListener("change", (e) => console.log(e));
        }, 5000);
    });
    
    return (
        <div className="page-container">
            <div className="form-group">
                <span className="label">Лицензия</span>
                <input type="text" placeholder="Лицензия" value={ license } onChange={ onChangeLicense } className="value"/>
            </div>
            <div className="form-group">
                <span className="label">Логин</span>
                <input type="text" placeholder="Логин" value={ username } onChange={ onChangeUsername } className="value"/>
            </div>
            <div className="form-group">
                <span className="label">Пароль</span>
                <input type="text" placeholder="Пароль" value={ password } onChange={ onChangePassword } className="value"/>
            </div>
            <div className="form-group actions">
                <button className="action-btn center" onClick={() => login()}>Зайти</button>
            </div>
        </div>
    );
};