import React, {CSSProperties, useEffect, useState} from 'react';
import {Table, Button, Dropdown, Pagination, Modal} from "react-bootstrap";
import {apiCall} from "../../API/ApiCall";
import {noty} from "../../Utils/NotyController";
import {useDispatch} from "react-redux";
import {DetailsIcon} from "../../Components/Icons/Details";
import {EditIcon} from "../../Components/Icons/Edit";
import {Link} from 'react-router-dom';

export const CafesList: React.FC = () => {
    const [showBanlist, setShowBanlist] = useState(false);
    const [defaultUsername, setDefaultUsername] = useState("");
    const [cacheSearch, setCacheSearch] = useState("");
    const [cafes, setCafes] = useState<Array<any>>([]);
    const [pageInfo, setPageInfo] = useState({limit: 16, offset: 0, count: 0, page: 1});

    const dispatch = useDispatch();

    function onSearch(search: string) {
        if (search.length == 0) return clearSearch();
        setCacheSearch(search);
    }

    function fetchCafes(search: string = ""): void {
        apiCall.get("/Cafe/GetAllCafes", {}).then(response => {
            setCafes(response.data);
            setPageInfo(prevState => ({
                ...prevState,
                ["count"]: response.data.count
            }));
        }).catch(e => {
            noty(e.response.data.error, "error");
        });
    }

    useEffect(() => {
        fetchCafes(cacheSearch);
    }, [pageInfo.offset, pageInfo.page, cacheSearch]);

    const prevPage = () => {
        if (pageInfo.page <= 1) return;

        setPageInfo(prevState => ({
            ...prevState,
            ["offset"]: (prevState.page - 2) * prevState.limit,
            ["page"]: prevState.page - 1
        }));
    };


    const nextPage = () => {
        if (pageInfo.page >= Math.round(pageInfo.count / pageInfo.limit + 0.45)) return;

        setPageInfo(prevState => ({
            ...prevState,
            ["offset"]: prevState.page * prevState.limit,
            ["page"]: prevState.page + 1
        }));
    };

    const clearSearch = () => {
        setCacheSearch("");
        setPageInfo(prevState => ({
            ...prevState,
            ["offset"]: 0,
            ["page"]: 1
        }));
    };

    return (
        <div style={{"width": "100%"}}>
            <div className="page-title">
                <h3>Список клубов</h3>
            </div>
            <div className="list-buttons">
                <Button variant="outline-success">Добавить</Button>
                <Button variant="outline-danger" style={{"marginLeft": "10px"}}>Удалить</Button>
            </div>
            <div className="list-table">
                {
                    (cafes.length > 0)
                        ? <Table striped bordered hover className="">
                            <thead>
                            <tr>
                                <th>Название</th>
                                <th>Адрес</th>
                                <th>ЦРМ</th>
                                <th>Кол-во пользователей</th>
                                <th>Действия</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                cafes.map((user: any) => {
                                    return (
                                        <tr key={user.id}>
                                            <td>{user.name}</td>
                                            <td>{user.address}</td>
                                            <td>{user.crmType}</td>
                                            <td>{user.usersCount}</td>
                                            <td className="d-flex">
                                                <Link to={`/cafes/details/${user.id}`}><DetailsIcon/></Link>
                                                <EditIcon/>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </Table>
                        : <div>...</div>
                }

                {cacheSearch.length === 0 &&
                    <Pagination>
                        <Pagination.Prev onClick={prevPage}/>
                        <Pagination.Item>{pageInfo.page} / {(pageInfo.count / pageInfo.limit >= 1) ? Math.round(pageInfo.count / pageInfo.limit + 0.45) : 1}</Pagination.Item>
                        <Pagination.Next onClick={nextPage}/>
                    </Pagination>
                }
            </div>
        </div>
    )
};