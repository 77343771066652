import React from "react";
import {Success} from "../../Components/Icons/Success";

export const PaymentSuccess: React.FC = () => {
    return (
        <div className="baseContainer" style={{marginTop: "10%", marginLeft: "30%", marginRight: "30%", textAlign: "center"}}>
            <div className="page-container">
                    <div className="page-title">
                        <h3>Успешно!</h3>
                    </div>
                <div >
                    <Success/>
                </div>
                <div style={{padding: "50px"}}>
                    Ваша оплата прошла успешно, деньги поступят на аккаунт в течение минуты!
                </div>
            </div>
        </div>
    );
};