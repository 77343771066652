export const initState = {
    enabled: false
};

export const loadingReducer = (state: any = initState, action: any) => {
    switch (action.type) {
        case 'loading/set':
            console.log(123)
            return { ...state, enabled: action.enabled };
        default:
            return state;
    }
};

export const setLoading = (loading: any) => ({
    type: 'SET_LOADING',
    loading,
});