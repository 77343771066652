import React from 'react';
import {Link} from 'react-router-dom';
import Button from 'react-bootstrap/Button';

export interface IUserDetailsItem {
    id: bigint
    username: string
    cafeName: string
    isBanned: boolean
    social: {
        phone: string | undefined
        email: string | undefined
        discordId: bigint | undefined
        telegramId: bigint | undefined
        vkontakteId: bigint | undefined
        steam: bigint | undefined
        instagram: string | undefined
    }
    gambleInfo: {
        lastUseRoulette: Date
        rouletteChance: number
        rouletteFreespins: bigint
    }
    roles: {
        name: string
    }[]
}

export const UserDetailsItem: React.FC<IUserDetailsItem> = ({
                                                                id,
                                                                username,
                                                                cafeName,
                                                                isBanned,
                                                                social,
                                                                gambleInfo,
                                                                roles
                                                            }) => {
    //TODO: complete links
    //TODO: complete gambleinfo and roles tabs

    return (
        <div className="details-view-container">
            <div className="details-header-buttons-container">
                <Button variant="outline-primary" href="/users/list">Вернуться</Button>
                <Button variant="outline-warning" style={{"float": "right", "marginLeft": "10px"}}>Редактировать</Button>
                <Button variant="outline-danger" style={{"float": "right"}}>Удалить</Button>
            </div>
            <div className="details-container">
                <div className="details-container_block">
                    <h1 className="details-container_block_title">Общее</h1>
                    <div className="details-container_block_container">
                        <div className="details-container_block_item">
                            <div className="details-container_block_item_title">
                                Айди:
                            </div>
                            <div className="details-container_block_item_value">
                                <>{id}</>
                            </div>
                        </div>
                        <div className="details-container_block_item">
                            <div className="details-container_block_item_title">
                                Юзернейм:
                            </div>
                            <div className="details-container_block_item_value">
                                <>{username}</>
                            </div>
                        </div>
                        <div className="details-container_block_item">
                            <div className="details-container_block_item_title">
                                Клуб:
                            </div>
                            <div className="details-container_block_item_value">
                                <>{cafeName}</>
                            </div>
                        </div>
                        <div className="details-container_block_item">
                            <div className="details-container_block_item_title">
                                Забанен:
                            </div>
                            <div className="details-container_block_item_value">
                                <>{isBanned ? "Да" : "Нет"}</>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="details-container_block">
                    <h1 className="details-container_block_title">Социалки</h1>
                    <div className="details-container_block_container">
                        <div className="details-container_block_item">
                            <div className="details-container_block_item_title">
                                Телефон:
                            </div>
                            <div className="details-container_block_item_value">
                                <>{social.phone ? social.phone : "-"}</>
                            </div>
                        </div>
                        <div className="details-container_block_item">
                            <div className="details-container_block_item_title">
                                Почта:
                            </div>
                            <div className="details-container_block_item_value">
                                <>{social.email ? social.email : "-"}</>
                            </div>
                        </div>
                        <div className="details-container_block_item">
                            <div className="details-container_block_item_title">
                                Дискорд:
                            </div>
                            <div className="details-container_block_item_value">
                                <>{
                                    social.discordId ?
                                        <Link to={`https://discord.com/users/${social.discordId}`}
                                              target={"_blank"}>Открыть</Link>
                                        : "-"
                                }</>
                            </div>
                        </div>
                        <div className="details-container_block_item">
                            <div className="details-container_block_item_title">
                                Телеграм:
                            </div>
                            <div className="details-container_block_item_value">
                                <>{
                                    social.telegramId ?
                                        <Link to={`tg://user?id=${social.telegramId}`} target={"_blank"}>Открыть</Link>
                                        : "-"
                                }</>
                            </div>
                        </div>
                        <div className="details-container_block_item">
                            <div className="details-container_block_item_title">
                                Вконтакте:
                            </div>
                            <div className="details-container_block_item_value">
                                <>{
                                    social.vkontakteId ?
                                        <Link to={`https://vk.com/id${social.vkontakteId}`}
                                              target={"_blank"}>Открыть</Link>
                                        : "-"
                                }</>
                            </div>
                        </div>
                        <div className="details-container_block_item">
                            <div className="details-container_block_item_title">
                                Стим:
                            </div>
                            <div className="details-container_block_item_value">
                                <>{social.steam ? social.steam : "-"}</>
                            </div>
                        </div>
                        <div className="details-container_block_item">
                            <div className="details-container_block_item_title">
                                Инстаграм:
                            </div>
                            <div className="details-container_block_item_value">
                                <>{social.instagram ? social.instagram : "-"}</>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}