import React from 'react';
import {Link} from 'react-router-dom';
import Button from 'react-bootstrap/Button';

export interface ICafeDetailsItem {
    id: bigint
    name: string
    address: string
    crmType: boolean
    social: {
        website: string | undefined
        phone: string | undefined
        email: string | undefined
        discordServerId: bigint | undefined
        telegramChatId: bigint | undefined
    }
    crmCredential: {
        licenseId: string | undefined
        cafeId: bigint | undefined
        login: string | undefined
        password: string | undefined
        server: string | undefined
    }
    cafeSettings: {
        defaultRoleId: bigint
        nextRouletteUseInMinutes: bigint
    }
    usersCount: bigint
}

export const CafeDetailsItem: React.FC<ICafeDetailsItem> = ({
                                                                id,
                                                                name,
                                                                address,
                                                                crmType,
                                                                social,
                                                                crmCredential,
                                                                cafeSettings,
                                                                usersCount
                                                            }) => {
    
    return (
        <div className="details-view-container">
            <div className="details-header-buttons-container">
                <Button variant="outline-primary" href="/cafes/list">Вернуться</Button>
                <Button variant="outline-warning"
                        style={{"float": "right", "marginLeft": "10px"}}>Редактировать</Button>
                <Button variant="outline-danger" style={{"float": "right"}}>Удалить</Button>
            </div>
            <div className="details-container">
                <div className="details-container_block">
                    <h1 className="details-container_block_title">Общее</h1>
                    <div className="details-container_block_container">
                        <div className="details-container_block_item">
                            <div className="details-container_block_item_title">
                                Айди:
                            </div>
                            <div className="details-container_block_item_value">
                                <>{id}</>
                            </div>
                        </div>
                        <div className="details-container_block_item">
                            <div className="details-container_block_item_title">
                                Название:
                            </div>
                            <div className="details-container_block_item_value">
                                <>{name}</>
                            </div>
                        </div>
                        <div className="details-container_block_item">
                            <div className="details-container_block_item_title">
                                Адрес:
                            </div>
                            <div className="details-container_block_item_value">
                                <>{address}</>
                            </div>
                        </div>
                        <div className="details-container_block_item">
                            <div className="details-container_block_item_title">
                                ЦРМ:
                            </div>
                            <div className="details-container_block_item_value">
                                <>{crmType}</>
                            </div>
                        </div>
                        <div className="details-container_block_item">
                            <div className="details-container_block_item_title">
                                Кол-во пользователей:
                            </div>
                            <div className="details-container_block_item_value">
                                <>{usersCount}</>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="details-container_block">
                    <h1 className="details-container_block_title">Социалки</h1>
                    <div className="details-container_block_container">
                        <div className="details-container_block_item">
                            <div className="details-container_block_item_title">
                                Телефон:
                            </div>
                            <div className="details-container_block_item_value">
                                <>{social.phone ? social.phone : "-"}</>
                            </div>
                        </div>
                        <div className="details-container_block_item">
                            <div className="details-container_block_item_title">
                                Почта:
                            </div>
                            <div className="details-container_block_item_value">
                                <>{social.email ? social.email : "-"}</>
                            </div>
                        </div>
                        <div className="details-container_block_item">
                            <div className="details-container_block_item_title">
                                Дискорд:
                            </div>
                            <div className="details-container_block_item_value">
                                <>{
                                    social.discordServerId
                                }</>
                            </div>
                        </div>
                        <div className="details-container_block_item">
                            <div className="details-container_block_item_title">
                                Телеграм:
                            </div>
                            <div className="details-container_block_item_value">
                                <>{
                                    social.telegramChatId
                                }</>
                            </div>
                        </div>
                        <div className="details-container_block_item">
                            <div className="details-container_block_item_title">
                                Сайт:
                            </div>
                            <div className="details-container_block_item_value">
                                <>{
                                    social.website
                                }</>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}