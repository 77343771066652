import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import './index.scss';
import LogoSVG from "../../Components/Logo";

export const Header: React.FC = () => {
    return (
        <Navbar bg="light" expand="lg">
            <Container>
                <Navbar.Brand href="/" style={{"width": "8%"}}><LogoSVG/></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        {/*<Nav.Link href="#test">test</Nav.Link>*/}
                        <NavDropdown title="Пользователи" id="basic-nav-dropdown">
                            <NavDropdown.Item href="/users/list">
                                Список пользователей
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/users/add">
                                Добавить пользователя
                            </NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="Клубы" id="basic-nav-dropdown">
                            <NavDropdown.Item href="/cafes/list">
                                Список клубов
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/cafes/add">
                                Добавить клуб
                            </NavDropdown.Item>
                            {/*<NavDropdown.Divider />
                            <NavDropdown.Item href="#action/3.4">
                                Divided link
                            </NavDropdown.Item>*/}
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};