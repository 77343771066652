import React, {CSSProperties, useEffect, useState} from 'react';
import {Table, Button, Dropdown, Pagination, Modal} from "react-bootstrap";
import {apiCall} from "../../API/ApiCall";
import {noty} from "../../Utils/NotyController";
import {useDispatch} from "react-redux";
import {DetailsIcon} from "../../Components/Icons/Details";
import {EditIcon} from "../../Components/Icons/Edit";
import {Link} from 'react-router-dom';

export const UsersList: React.FC = () => {
    const [showBanlist, setShowBanlist] = useState(false);
    const [defaultUsername, setDefaultUsername] = useState("");
    const [cacheSearch, setCacheSearch] = useState("");
    const [users, setUsers] = useState<Array<any>>([]);
    const [pageInfo, setPageInfo] = useState({limit: 16, offset: 0, count: 0, page: 1});

    const dispatch = useDispatch();

    function onSearch(search: string) {
        if (search.length == 0) return clearSearch();
        setCacheSearch(search);
    }

    function fetchUsers(search: string = ""): void {
        apiCall.get("/User/GetAllUsers", {}).then(response => {
            setUsers(response.data);
            setPageInfo(prevState => ({
                ...prevState,
                ["count"]: response.data.count
            }));
        }).catch(e => {
            noty(e.response.data.error, "error");
        });
    }

    function createUser() {
        const userlogs: string | null = prompt(`Введи данные игрока форматом nickname:password:email:uuid Почту и юид вводить не обязательно. Пример: vladuska:qwerty123:penis@gmail.com:123012930:123989123:123`);
        const user = userlogs?.split(":");
        let nickname: string = "";
        let password: string = "";
        let email: string = " ";
        let uuid: string = " ";
        if (user) {
            nickname = user[0];
            password = user[1];
            if (user[2]) email = user[2];
            if (user[3]) uuid = user[3];
        }
        if (nickname.length < 1 || password.length < 1) return noty("Пароль или ник слишком короткий!", "error");

        apiCall.post("/admin/users/create-user", {nickname, password, email, uuid}).then(response => {
            if (response.data == "exists") return noty("Игрок с такой почтой или ником уже существует!", "error");
            fetchUsers(cacheSearch);
        }).catch(e => {
            noty(e.response.data.error, "error");
        });
    }

    function changePassword(username: string, id: number) {
        const password: string | null = prompt(`Введи новый пароль`);

        if (!password) return noty("Отмена", "info");

        if (password.length < 1) return noty("Пароль слишком короткий!", "error");

        apiCall.post("/admin/users/change-password", {username, password, id}).then(response => {
            fetchUsers(cacheSearch);
            return noty(`Успешно изменен пароль для игрока ${username}!`, "success");
        }).catch(e => {
            noty(e.response.data.error, "error");
        });
    }

    function changeRole(uuid: string, username: string) {
        const role: string | null = prompt(`Введи новое название роли маленькими буквами`);

        if (!role) return noty("Отмена", "info");

        if (role.length < 1) return noty("Неверное название!", "error");

        apiCall.post("/admin/users/change-role", {uuid, role, username}).then(response => {
            fetchUsers(cacheSearch);
            return noty(`Успешно изменена роль игрока ${username}!`, "success");
        }).catch(e => {
            noty(e.response.data.error, "error");
        });
    }

    function onBan(ip: string, username: string, reason: string, time: number): void {
        apiCall.post("/admin/users/ban", {ip, username, reason, time}).then(response => {
            fetchUsers(cacheSearch);
            setShowBanlist(false);
        }).catch(e => {
            noty(e.response.data.error, "error");
        });
    }

    function showBanlistCard(username: string): void {
        setDefaultUsername(username);
        setShowBanlist(true);
    }

    function changeMoney(id: number, key: string, username: string): void {
        const sum: string | null = prompt(`Новое значение валюты`);
        const value = (sum) ? parseInt(sum) : null;

        if (isNaN(value as number)) return noty("Введите корректное число", "error");

        apiCall.post("/admin/users/change-money", {id, key, value, username}).then(response => {
            fetchUsers(cacheSearch);
        }).catch(e => {
            noty(e.response.data.error, "error");
        });
    }

    function deleteUser(username: string, id: number): void {
        /*const dick: string | null = prompt(`Для подтверждения, напиши никнейм ${username} снизу`);
    
        if(!dick?.match(username)) return noty("Отменено", "info");*/

        apiCall.post("/admin/users/delete-user", {username, id}).then(response => {
            fetchUsers(cacheSearch);
            return noty(`Успешно удален игрок ${username}!`, "success");
        }).catch(e => {
            noty(e.response.data.error, "error");
        });
    }

    useEffect(() => {
        fetchUsers(cacheSearch);
    }, [pageInfo.offset, pageInfo.page, cacheSearch]);

    const prevPage = () => {
        if (pageInfo.page <= 1) return;

        setPageInfo(prevState => ({
            ...prevState,
            ["offset"]: (prevState.page - 2) * prevState.limit,
            ["page"]: prevState.page - 1
        }));
    };

    function unbanUser(id: number, username: string): void {
        apiCall.post("/admin/users/unban", {id, username}).then(response => {
            fetchUsers(cacheSearch);
        }).catch(e => {
            noty(e.response.data.error, "error");
        });
    }

    const nextPage = () => {
        if (pageInfo.page >= Math.round(pageInfo.count / pageInfo.limit + 0.45)) return;

        setPageInfo(prevState => ({
            ...prevState,
            ["offset"]: prevState.page * prevState.limit,
            ["page"]: prevState.page + 1
        }));
    };

    const clearSearch = () => {
        setCacheSearch("");
        setPageInfo(prevState => ({
            ...prevState,
            ["offset"]: 0,
            ["page"]: 1
        }));
    };

    return (
        <div className="page-container">
            <div className="page-title">
                <h3>Список пользователей</h3>
            </div>
            <div className="list-buttons">
                <Button variant="outline-success">Добавить</Button>
                <Button variant="outline-danger" style={{"marginLeft": "10px"}}>Удалить</Button>
            </div>
            <div className="list-table">
                {
                    (users.length > 0)
                        ? <Table striped bordered hover className="">
                            <thead>
                            <tr>
                                <th>Юзернейм</th>
                                <th>Клуб</th>
                                <th>Забанен?</th>
                                <th>Действия</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                users.map((user: any) => {
                                    return (
                                        <tr key={user.id}>
                                            <td>{user.username}</td>
                                            <td>{user.cafeName}</td>
                                            <td>{user.isBanned ? "Да" : "Нет"}</td>
                                            <td className="d-flex">
                                                <Link to={`/users/details/${user.id}`}><DetailsIcon/></Link>
                                                <EditIcon/>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </Table>
                        : <div>...</div>
                }

                {cacheSearch.length === 0 &&
                    <Pagination>
                        <Pagination.Prev onClick={prevPage}/>
                        <Pagination.Item>{pageInfo.page} / {(pageInfo.count / pageInfo.limit >= 1) ? Math.round(pageInfo.count / pageInfo.limit + 0.45) : 1}</Pagination.Item>
                        <Pagination.Next onClick={nextPage}/>
                    </Pagination>
                }
            </div>
        </div>
    )
};